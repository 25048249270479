import React, {
	// useState,
	// useRef,
	// useEffect,
	// useCallback,
} from "react";

import ReactDOMServer from "react-dom/server";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";


import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";


import useRegistration from "hooks/useRegistration";
import useSendEmail from "hooks/useSendEmail";
import useSavedHomes from "hooks/useSavedHomes";


// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import mapMarkerAmp from "img/map_marker_amp.svg";
import xWhiteMedium from "img/x_white_medium.svg";
import DialogGeneric from "./DialogGeneric";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'fixed',
			top: '45%',
			left: '50%',

			padding: 30,
			paddingLeft: 50,
			paddingRight: 50,

			// width: 700,
			// height: 330,

			color: ThemeData.colours.white,
			// backgroundColor: ThemeData.colours.primaryBg,
			// VonE49 colour
			backgroundColor: ThemeData.colours.prussianBlue,

			textAlign: 'left',

			...ThemeData.styles.hoverPaneBoxShadow,
		},
		closeX: {
			position: 'absolute',
			top: 20,
			right: 20,

			cursor: 'pointer',
		},
		title: {
			fontSize: 30,
			fontFamily: ThemeData.fonts.proximaNovaBold,
		},
		label: {
			display: 'inline-block',
			marginTop: 40,

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		input: {
			width: 335,

			border: 'none',
			// borderBottom: `1px ${ThemeData.colours.primaryAccent} solid`,
			borderBottom: `1px ${ThemeData.colours.cadetBlue} solid`,

			color: ThemeData.colours.white,
			// backgroundColor: ThemeData.colours.primaryBg,
			// VonE49 colour
			backgroundColor: ThemeData.colours.prussianBlue,

			fontFamily: ThemeData.fonts.copy,
			fontSize: 16,

			"&:focus, &:active": {
				outline: 'none',

				border: 'none',
				// borderBottom: `1px ${ThemeData.colours.primaryAccent} solid`,
				borderBottom: `1px ${ThemeData.colours.cadetBlue} solid`,
			},
		},
		emailInput: {
			width: 695,
		},
		firstName: {
			// position: 'absolute',
			// top: 50,
			// left: 50,
		},
		lastName: {
			paddingLeft: 20,
			// position: 'absolute',
			// top: 50,
			// left: 400,
		},
		email: {
			// position: 'absolute',
			// top: 150,
			// left: 50,
		},
		submitButton: {
			width: 'auto !important',

			fontFamily: ThemeData.fonts.proximaNova,
			fontSize: 16,
		},
		cancelButton: {
			marginTop: 50,

			backgroundColor: 'transparent',
			color: ThemeData.colours.white,
			border: `none`,

			cursor: 'pointer',

			fontFamily: ThemeData.fonts.proximaNova,
			fontSize: 16,
			marginRight: 30,
		},
		buttons: {
			display: 'inline-flex',
			float: 'right',
		}
	},
	{
		name: "Register",
	}
)



const SubmitButton = styled( Button )( {

	height: 60,
	width: 90,

	marginTop: 50,

	color: ThemeData.colours.white,
	border: `${ThemeData.colours.white} solid`,
	borderWidth: '0.1px',
	borderRadius: '0px !important',

	fontFamily: ThemeData.fonts.titleSub,
	fontSize: 16,
	'&:hover': {
		border: `1px ${ThemeData.colours.tealBlue} solid`,
	},


} );



function RenderRegister( classes, data, error, doShow, props, children ) {

	const { registration, setFirstName, setLastName, setEmail } = useRegistration();
	const { sendSavedHomesEmail } = useSendEmail();
	const { savedHomes } = useSavedHomes();

	let savedHomesData = savedHomes.map( savedHomeUnit => {
		if (data && data.units) { 
			let unitIdx = data.units.findIndex( elm => elm.id === savedHomeUnit.id );

			if ( unitIdx !== -1 ) return data.units[unitIdx];
		}
	})

	// console.log(savedHomes)
	// console.log(data)


	const handleSubmit = ( e ) => {
		// console.log( "submit", registration.fName, registration.lName, registration.email );

		e.preventDefault();

		sendSavedHomesEmail( `${registration.fName} ${registration.lName}`, registration.email, savedHomesData );

		props.toggleIsOpen();
	};


	return (
		<DialogGeneric
			isDialogOpen={doShow}
			setIsDialogOpenToClosed={props.toggleIsOpen}
			ariaLabelledBy="email-dialog-title"
			ariaDescribedBy="email-dialog-confirmation-action-button"
		>
			<motion.div
				className={classes.wrapper}
				initial={"dontShow"}
				animate={doShow ? "show" : "dontShow"}
				variants={{
					show: {
						x: '-50%',
						y: '-50%',
						zIndex: 4,
						display: 'block',
					},
					dontShow: {
						x: "100%",
						y: '-50%',
						display: 'block',
						transitionEnd: {
							zIndex: -2,
							display: 'none',
						}
					},
				}}
				transition={{
					duration: .5,
				}}
			>
				{/* <div
					className={classes.closeX}
					onClick={() => props.toggleIsOpen()}>
					<img src={xWhiteMedium} alt="Slide Away Saved Homes" />
				</div> */}
				<div
					id={"email-dialog-title"}
					className={classes.title}>
					{props.titleText}
				</div>
				<form onSubmit={handleSubmit}>
					<label className={`${classes.label} ${classes.firstName}`}>
						First Name:<br /><br />
						<input className={classes.input} value={registration.fName} onChange={evt => setFirstName( evt.target.value )}></input>
					</label>
					<label className={`${classes.label} ${classes.lastName}`}>
						Last Name:<br /><br />
						<input className={classes.input} value={registration.lName} onChange={evt => setLastName( evt.target.value )}></input>
					</label><br />
					<label className={`${classes.label} ${classes.email}`}>
						Email:<br /><br />
						<input className={`${classes.input} ${classes.emailInput}`} value={registration.email} onChange={evt => setEmail( evt.target.value )}></input>
					</label><br />
					<div className={classes.buttons}>
						<button
							type='button'
							className={classes.cancelButton}
							onClick={props.toggleIsOpen}
						>
							CANCEL
						</button>
						<SubmitButton
							id="email-dialog-confirmation-action-button"
							className={classes.submitButton}
							sx={{
								...classes.submitButton,
							}}
							type='submit'
							value='Send Email'
							variant='outlined'
						>
							{props.buttonText}
						</SubmitButton>
					</div>
				</form>
			</motion.div>
		</DialogGeneric>
	);
}

const Register = ( { doShow, children, ...props } ) => {

	const classes = useStyles();

	const { loading, error, data } = useQuery( QueryData.units );

	// const { loading, error, data } = useQuery( QueryData.neighbourhoodLocationCategories );

	// if( loading ) return RenderRegister( classes, null, null, doShow, props );
	// if( error ) return RenderRegister( classes, null, error, doShow, props );

	// console.log( data );
	// console.log( "reg.props", props );

	return RenderRegister( classes, data, null, doShow, props, children );

};



Register.defaultProps = {
	titleText: "Send PDF to email",
	buttonText: "Send",
}



export default Register;