import {
	useRouteMatch,
	Link,
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import ThemeData from "../data/ThemeData";
import ConstantData from "data/ConstantData";
// import NetData from "../data/NetData";

import imgFunctions from "util/imgFunctions";
import richifyTextBasic from "util/richifyTextBasic";

import contentCardPlus from "../img/content_card_plus.svg";

const useStyles = createUseStyles(
	{
		contentCardItem: {
			position: 'relative',

			flex: '0 0 auto',
			display: 'flex',

			overflow: 'hidden',
			// overflow: 'clip',

			'&:hover $bgImg': {
				transform: 'scale(1.1)',
			},
			'&:hover $bgImgNoDark': {
				transform: 'none !important',
			},

			'& a, & a:visited': {
				// color: `${ThemeData.colours.eggshellTextBg}`,
				color: `${ThemeData.colours.primaryFont}`,
			},
		},
		contentCardTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		contentCardTitle: {
			flex: '0 1 auto',

			padding: 60,

			...ThemeData.typography.contentCardTitles,
		},
		contentCardPlus: {
			position: 'absolute',
			right: 0,

			width: 100,
			height: 100,

			background: 'no-repeat center',
			backgroundImage: `url('${contentCardPlus}')`
		},
		oneUp: {
			height: 'calc( 50% - 10px )',
		},
		twoUpHoriz: {
			// flex: '1 1 auto',
			// width: '66%',
			width: '100%'
		},
		twoUpVert: {
			// flex: '1 1 auto',
			// width: '33%',
			height: '100%',
		},
		fourUp: {
			// flex: '1 1 auto',
			width: '100%',
			height: '100%',
		},
		sixUp: {
			width: '100%',
			height: '100%',
		},
		equalSized: {
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		bgImg: {
			background: `no-repeat center`,
			backgroundSize: 'cover',

			opacity: .5,

			transition: 'all .4s ease',
		},
		bgImgNoDark: {
			opacity: 1,

			backgroundSize: 'contain',
		},
		bgImgBlackBg: {
			backgroundColor: 'black',
		},
		imgCard: {
			width: '100%',
			height: '100%',
			objectFit: 'contain',
		},
	},
	{
		name: "ContentCard"
	}
);

function ContentCard( { contentPage } ) {

	const classes = useStyles();
	// const apiUri = NetData.apiUri;
	const matchUri = useRouteMatch();

	// console.log( contentPage );
	// console.log( contentPage.image[0] );

	let contentPageUrl = `${matchUri.url}/${contentPage.slug}`;

	if ( contentPage.custom_link ) {
		contentPageUrl = `${contentPage.custom_link}`;
	}

	// Figure out if the Card should be dark with a title over it or not
	let doNotDarkenOrTitleCard = false;
	if ( contentPage.section.slug === "gallery" )
		doNotDarkenOrTitleCard = true;
	if ( contentPage.is_only_image_card )
		doNotDarkenOrTitleCard = true;

	// console.log( contentPage );

	const renderContent = () => {

		return (
			<div className={`${contentPage.form && classes[contentPage.form.value]} ${classes.contentCardItem}`} key={contentPage.id}>
				{contentPage.image[0] && contentPage.image[0].url.includes( '.mp4' ) ?
					<video
						style={{ width: '100%', objectFit: 'cover' }}
						// controls
						autoPlay
						muted
						loop
					>
						<source src={contentPage.image[0].url} type="video/mp4" />
					</video>
					:
					<>
						{contentPage.is_only_image_card ?
							<></> :
							<div className={`${classes.equalSized} ${classes.bgImgBlackBg}`}>
							</div>}
						<div
							className={`${classes.equalSized} ${classes.bgImg} ${doNotDarkenOrTitleCard ? classes.bgImgNoDark : ""}`}
							style={!contentPage.is_only_image_card ? contentPage.image[0] && {
								backgroundImage: `url(${imgFunctions.GetImgBySizeUri( contentPage.image[0], ConstantData.imgSizes.large )})`
							} : null}>
							{contentPage.is_only_image_card &&
								<img
									className={classes.imgCard}
									alt={contentPage.image[0].alternativeText}
									src={imgFunctions.GetImgBySizeUri( contentPage.image[0], ConstantData.imgSizes.large )}
									width={imgFunctions.GetImgBySizeWidth( contentPage.image[0], ConstantData.imgSizes.large )}
									height={imgFunctions.GetImgBySizeHeight( contentPage.image[0], ConstantData.imgSizes.large )}
								/>
							}
						</div>
						{contentPage.is_only_image_card ?
							<></> :
							<Link to={contentPageUrl}>
								<div className={`${classes.equalSized} ${classes.contentCardTitleContainer}`}>
									<div className={classes.contentCardTitle}>
										<p dangerouslySetInnerHTML={{
											__html: doNotDarkenOrTitleCard ?
												"" :
												richifyTextBasic( contentPage.card_title ?
													contentPage.card_title :
													contentPage.title )
										}} />
									</div>
								</div>
								<div className={classes.contentCardPlus} />
							</Link>
						}
					</>
				}
			</div>
		)
	}

	return (
		renderContent()
	)
}


export default ContentCard;