import React, {
	useState,
	useCallback,
	useEffect,
	useMemo,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";

import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";


import ThemeData from "data/ThemeData";
import QueryData from "data/QueryData";
import ConstantData from "data/ConstantData";


import imgFunctions from "util/imgFunctions";
import ScrollIcon from "./ScrollIcon";


// import devLogo from "img/kindred_logo_darkFill_small.png";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			boxSizing: 'border-box',
			width: '100%',
			height: props.withPhotosOfCategoryName ? 'calc( 100vh - 100px ) ' : 'calc( 100vh - 200px )',

			padding: !props.noPadding ? 20 : 0,

			textAlign: 'left',

			overflowY: 'auto',

			...ThemeData.styles.thinScrollbarLightOnDark,
		} ),

		scroll: {
			color: 'white',
			position: 'absolute',
			height: '48px',
			width: '48px',
			bottom: '120px',
		},

		categoryButtons: {
			padding: '5px 15px 15px',
		},

		buttonGroup: {
			justifyContent: 'flex-start',
		},

		buttonOutline: {
			borderRadius: '50px',
			padding: '15px 15px',
		}
	},
	{
		name: "Gallery",
	}
)



export const photos = [
	{
		src: "https://placekitten.com/300/400",
		width: 3,
		height: 4
	},
	{
		src: "https://placekitten.com/800/800",
		width: 1,
		height: 1
	},
	{
		src: "https://placekitten.com/300/400",
		width: 3,
		height: 4
	},
	{
		src: "https://placekitten.com/400/300",
		width: 4,
		height: 3
	},
	{
		src: "https://placekitten.com/800/800",
		width: 1,
		height: 1
	},
	{
		src: "https://placekitten.com/400/300",
		width: 4,
		height: 3
	},
];



const RenderGallery = ( data, error, classes, props ) => {

	if ( error ) {
		console.log( "Gallery Error:", error.message );
	}


	const [currentImage, setCurrentImage] = useState( 0 );
	const [isViewerOpen, setIsViewerOpen] = useState( false );
	const [showCategory, setShowCategory] = useState( ConstantData.galleryPhotoDefaultCategories.all );
	const [categories, setCategories] = useState( [] );

	const openLightbox = useCallback( ( e, { photo, index } ) => {
		// console.log( 'e', e, 'photo', photo, 'index', index );
		setCurrentImage( index );
		setIsViewerOpen( true );
	} );

	const closeLightbox = () => {
		setCurrentImage( 0 );
		setIsViewerOpen( false );
	};

	const validatePhotoCategories = ( photo ) => {
		if ( photo.gallery_photo_categories ) {
			if ( photo.gallery_photo_categories[0] === undefined || photo.gallery_photo_categories.length === 0 ) {
				return false;
			}

			return true
		}

		return false;
	};

	useEffect( () => {
		let categoryTypes = [ConstantData.galleryPhotoDefaultCategories.all];

		if ( data ) {
			let addCategoryTypes = [...data.galleryPhotos].reduce( ( catBuild, photo ) => {
				photo.gallery_photo_categories && photo.gallery_photo_categories.forEach( cat => {
					if ( !catBuild.includes( cat.name ) )
						catBuild.push( cat.name );
				} )
				return catBuild;
			}, [] );
			categoryTypes = categoryTypes
				.concat(
					addCategoryTypes.sort( ( catNameA, catNameB ) => catNameA.localeCompare( catNameB ) )
				);

			setCategories( categoryTypes )
		}
	}, [data] );


	const galleryPhotos = useMemo( () => {
		let galleryPhotos = [{}];

		if ( data )
			galleryPhotos =
				[...data.galleryPhotos]
					.sort( ( phA, phB ) => phA.order - phB.order )
					.map( photo => {
						return {
							src: imgFunctions.GetImgBySizeUri( photo.image, ConstantData.imgSizes.xlarge ),
							width: imgFunctions.GetImgBySizeWidth( photo.image, ConstantData.imgSizes.xlarge ),
							height: imgFunctions.GetImgBySizeHeight( photo.image, ConstantData.imgSizes.xlarge ),
							category: validatePhotoCategories( photo ) ? photo.gallery_photo_categories.map( cat => cat.name ) : ''
						};
					} )

		return galleryPhotos;
	}, [data] );


	const filteredPhotos = useMemo( () => {
		let filteredPhotos = [{}]

		if ( filteredPhotos ) {
			filteredPhotos = galleryPhotos.filter( photo => {
				if ( showCategory === ConstantData.galleryPhotoDefaultCategories.all ) {
					return photo;
				} else if ( photo.category.includes( showCategory ) )
					return photo;

				return null;
			} );
		}

		// console.log( "fgp", filteredPhotos );

		return filteredPhotos;
	}, [showCategory, galleryPhotos] )


	return (
		<div id="vertical">
			<div className={`${classes.wrapper} ${props.className}`}>
				{categories && categories.length > 1 &&
					<div className={classes.categoryButtons}>
						<ButtonGroup className={classes.buttonGroup}>
							{categories.map( ( category, idx ) =>
								<ButtonOutline
									className={classes.buttonOutline}
									key={category}

									textColour={ThemeData.colours.pearlWhite}
									hoverTextColour={ThemeData.colours.black}
									// backgroundColour={'clear'}
									backgroundColour={ThemeData.colours.clear}
									borderColour={ThemeData.colours.primaryFont}
									firstInColl={idx === 0}

									isActive={showCategory === category}
									onClick={() => setShowCategory( category )}
								>
									{category}
								</ButtonOutline>
							)}
						</ButtonGroup>
					</div>
				}

				<PhotoGallery
					photos={filteredPhotos}
					onClick={openLightbox}
					direction='column'
					columns={3}
					margin={15}
				/>
				<ModalGateway>
					{isViewerOpen ? (
						<Modal onClose={closeLightbox}>
							<Carousel
								currentIndex={currentImage}
								views={
									filteredPhotos.map( x => ( {
										...x,
										key: x.src,
										srcset: x.srcset,
										caption: x.title,
									} ) )
								}
							/>
						</Modal>
					) : null}
				</ModalGateway>
			</div>
			<ScrollIcon direction={ConstantData.scrollIconDir.vertical} />
		</div>
	);
};



const Gallery = ( { ...props } ) => {

	const classes = useStyles( props );

	let queryToUse = QueryData.galleryPhotosMainGallery;
	// let queryToUse = '';
	let queryVars = {};
	if ( props.doOverrideUseFullPhotoGallery ) {
		queryToUse = QueryData.galleryPhotos;
	} else if ( props.withPhotosOfCategoryName ) {
		queryToUse = QueryData.galleryPhotosOfCategory;
		queryVars = {
			variables: {
				categoryName: props.withPhotosOfCategoryName
			}
		}
	}

	// console.log( "overrideUseFull", props.doOverrideUseFullPhotoGallery, "withPhotosOfCat", props.withPhotosOfCategoryName );

	const { loading, error, data } = useQuery( queryToUse, queryVars );

	if ( loading ) return RenderGallery( null, null, classes, props );
	if ( error ) return RenderGallery( null, error, classes, props );


	// console.log( 'gphData', data );


	return RenderGallery( data, null, classes, props );

}



Gallery.defaultProps = {
	noPadding: false,
	doOverrideUseFullPhotoGallery: false,
	withPhotosOfCategoryName: null,
};



export default Gallery;