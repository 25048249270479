import React, {
	useState,
	useEffect,
	useContext,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
} from "framer-motion";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import {
	BiCheck,
} from "react-icons/bi";
import {
	GrClose,
} from "react-icons/gr";



import ThemeData from "data/ThemeData";
import ConstantData from "data/ConstantData";
// import QueryData from "data/QueryData";
import SiteConfigData from "data/SiteConfigData";


// import UnitDetailListItem from "./UnitDetailListItem";
// import UnitDetailCompareCard from "components/UnitDetailCompareCard";
// import SavedHomesTitle from "components/SavedHomesTitle";
// import ButtonOutline from "components/ButtonOutline";
// import ButtonGroup from "./ButtonGroup";
import UnitCardActionButton from "./UnitCardActionButton";
import UnitCardsParent from "./UnitCardsParent";
// import UnitDetailCompareCard from "./UnitDetailCompareCard";
import UnitDetailCardGeneric from "./UnitDetailCardGeneric";
import UnitDetailTableItem from "./UnitDetailTableItem";
// import UnitDetailCompareCard from "./UnitDetailCompareCard";
// import UnitDetailCard from "./UnitDetailCard";
import UnitDetailPane from "./UnitDetailPane";
import VirtualTour from "./VirtualTour";


// import useSavedHomes from "hooks/useSavedHomes";
import preventClickEventPassthrough from "util/preventClickEventsPassthrough";
// import UtilFcns from "util/UtilFcns";
import useOpenClosePane from "hooks/useOpenClosePane";
import useSiteConfiguration from "hooks/useSiteConfiguration";
// import useUnitFcns from "hooks/useUnitFcns";


import UnitFcnContext from "contexts/UnitFcnContext";


// import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
// import xWhiteMedium from "img/x_white_medium.svg";
// import iconChevronDown from "img/icon_chevron_down_filled_white.svg";
// import SavedHomesCompareList from "./SavedHomesCompareList";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			height: '100%',
			width: '100%',
			boxSizing: 'border-box',
			overflow: 'hidden',

			color: ThemeData.colours.white,
			// backgroundColor: props.overrideBackgroundColor || ThemeData.colours.primaryBg,

			// VonE9 colour 
			backgroundColor: ThemeData.colours.prussianBlue,

			letterSpacing: '.025em',
		} ),
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.white50,
			fontFamily: ThemeData.fonts.copy,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',

			overflow: 'hidden',
		},
		topRightContainer: {
			position: 'absolute',
			top: 60,
			right: 60,

			display: 'flex',
			alignItems: 'center',
		},
		closeX: {
			marginTop: 10,
			marginLeft: 50,

			cursor: 'pointer',
		},
		sendToEmailButton: {
			display: 'inline-block',

			width: 150,

			fontSize: 12,
			letterSpacing: '.1em',
		},
		savedHomesTitle: {
			textAlign: 'left',

			fontSize: 24,
			letterSpacing: '.15em',
		},
		top: {
			flex: '0 0 auto',
		},
		addHomesButton: {
			width: 220,

			fontSize: 12,
			letterSpacing: '.1em',

			borderRadius: 25,
		},
		middle: {
			flex: '1 0 auto',
		},
		unitDetailCompareCardWrapper: {
			flex: '0 0 auto',

			width: 575,

			padding: 20,
		},
		unitCard: {
			color: ThemeData.colours.black,
		},
		listModeWrapper: {
			// display: 'flex',
			height: 'calc( 100vh - var( --header-height ) )',
			// boxSizing: 'border-box',
		},
		listModeUnit: {
			height: '100%',
			// width: '50%',
			// boxSizing: 'border-box',
		},
		// listModeList: {
		// 	width: '50%',
		// 	boxSizing: 'border-box',
		// },
		tableWrapper: {
			padding: '100px 50px',
			// marginBottom: 50,
			boxSizing: 'border-box',
		},
		tableItemRowsWrapper: {
			flexDirection: 'column',
			flexWrap: 'nowrap',
			justifyContent: 'start',
			paddingTop: 0,
		},
		tableItemRow: {
			flex: '0 0 auto',
		},
		bottom: {
			flex: '0 0 auto',
			display: 'flex',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
	},
	{
		name: "FloorplansGeneric",
	},
)





const RenderFloorplansGeneric = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	if ( error ) {
		console.log( "RenderFloorplans", error.message );
	}


	const siteConfiguration = useSiteConfiguration();


	const {
		selectedUnitId,
		setSelectedUnitId,

		// isUnitDetailOpen,
		setIsUnitDetailOpen,
		toggleIsUnitDetailOpen,
	} = useContext( UnitFcnContext );


	const [unitFilterViewMode, setUnitFilterViewMode] = useState( ConstantData.unitFilterViewModes.card );

	// const [isCompareModeOn, setIsCompareModeOn] = useState( false );
	const [compareUnitCustomId1, setCompareUnitCustomId1] = useState( null );
	const [compareUnitCustomId2, setCompareUnitCustomId2] = useState( null );
	const [dataUnitCustomId1, setDataUnitCustomId1] = useState( null );
	const [dataUnitCustomId2, setDataUnitCustomId2] = useState( null );

	useEffect( () => {
		// console.log( 'cuci1', compareUnitCustomId1, 'cuci2', compareUnitCustomId2, )

		if ( compareUnitCustomId2 && !compareUnitCustomId1 ) {
			setCompareUnitCustomId1( compareUnitCustomId2 );
			setCompareUnitCustomId2( null );
		}
	}, [compareUnitCustomId1, compareUnitCustomId2] )

	useEffect( () => {
		// console.log( "duci1", dataUnitCustomId1 );
	}, [dataUnitCustomId1] );
	useEffect( () => {
		// console.log( "duci2", dataUnitCustomId2 );
	}, [dataUnitCustomId2] );


	const {
		isVirtualTourOpen,
		toggleIsVirtualTourOpen,
	} = useOpenClosePane( "VirtualTour" )


	useEffect( () => {
		// console.log( 'selUnitId', props.selectedUnitId );
		// console.log( 'fgSetSelUnit', props.setSelectedUnitId );
		// console.log( 'fgProps', props );
		// setSelectedUnitData( dataUnit.filter( unit => unit.id === selectedUnitId ) );
	}, [selectedUnitId] )

	useEffect( () => {
		// console.log( 'selUnitData', selectedUnitData );
	}, [props.selectedUnitData] )



	return (
		<>
			<motion.div
				className={classes.wrapper}
				// initial={false}
				// animate={isOpen ? "open" : "closed"}
				// variants={wrapperAnimVariants}
				// transition={{
				// 	type: "tween",
				// 	ease: "easeOut",
				// 	duration: .75,
				// }}
				onMouseDown={( e ) => preventClickEventPassthrough( e )}
				onMouseUp={( e ) => preventClickEventPassthrough( e )}
			>

				{unitFilterViewMode === ConstantData.unitFilterViewModes.card || unitFilterViewMode === ConstantData.unitFilterViewModes.compare ?
					<>
						<UnitCardsParent
							unitFilterViewMode={unitFilterViewMode}
							setUnitFilterViewMode={setUnitFilterViewMode}

							isToAllowCompare={props.isToAllowCompare}
							compareUnitCustomId1={compareUnitCustomId1}
							compareUnitCustomId2={compareUnitCustomId2}
							setDataUnitCustomId1={setDataUnitCustomId1}
							setDataUnitCustomId2={setDataUnitCustomId2}
							// dontShowFilterSort

							selectedUnitId={selectedUnitId}
							setSelectedUnitId={setSelectedUnitId}
							setSelectedUnitData={props.setSelectedUnitData}

							dualPaneLeftPane={unitFilterViewMode === ConstantData.unitFilterViewModes.compare && ( compareUnitCustomId1 || compareUnitCustomId2 ) &&
								<motion.div
									className={classes.listModeUnit}
									{...ThemeData.anim.defaultOpacityFade}
									layout
								// key={dataUnitCustomId1 && dataUnitCustomId1.custom_id}
								>
									<UnitDetailPane
										dataUnit={dataUnitCustomId1 || dataUnitCustomId2}

										altTextUnitActionButton={`Stop Comparing Left Unit`}
										onClickUnitActionButton={() => {
											if ( dataUnitCustomId1 ) {
												setCompareUnitCustomId1( null );
												return;
											}
											setCompareUnitCustomId2( null );
										}}
										labelUnitActionButton={<GrClose />}

										setSelectedUnitId={setSelectedUnitId}
										toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
									/>
								</motion.div>
							}
							dualPaneRightPane={unitFilterViewMode === ConstantData.unitFilterViewModes.compare && ( compareUnitCustomId1 && compareUnitCustomId2 ) &&
								<motion.div
									className={classes.listModeUnit}
									{...ThemeData.anim.defaultOpacityFade}
									layout
								// key={dataUnitCustomId2 && dataUnitCustomId2.custom_id}
								>
									<UnitDetailPane
										dataUnit={dataUnitCustomId2}

										altTextUnitActionButton={`Stop Comparing Right Unit`}
										onClickUnitActionButton={() => {
											setCompareUnitCustomId2( null );
										}}
										labelUnitActionButton={<GrClose />}

										setSelectedUnitId={setSelectedUnitId}
										toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
									/>
								</motion.div>
							}
						>
							{( className, dataUnit, isFirstItem, toggleSavedHome ) => (
								// <UnitDetailCompareCard
								<UnitDetailCardGeneric
									className={className}
									dataUnit={dataUnit}
									// isFirstItem={isFirstItem}
									toggleSavedHome={toggleSavedHome}

									unitCardActionButton={
										<UnitCardActionButton
											dataUnit={dataUnit}

											isToShow={unitFilterViewMode === ConstantData.unitFilterViewModes.compare}
											altText={`Compare Unit ${dataUnit.unit_number}`}

											textColourInactive={ThemeData.colours.cadetBlue}
											textColourActive={ThemeData.colours.pearlWhite}
											backgroundColourInactive={ThemeData.colours.cardBg}
											backgroundColourActive={ThemeData.colours.cadetBlue}
											// borderColour={ThemeData.colours.grey25}

											borderColour={ThemeData.colours.cadetBlue}
											textColour={ThemeData.colours.cadetBlue}

											onClick={() => {
												if ( dataUnit.custom_id === compareUnitCustomId1 ) {
													setCompareUnitCustomId1( null );
													return;
												}
												if ( dataUnit.custom_id === compareUnitCustomId2 ) {
													setCompareUnitCustomId2( null );
													return;
												}

												if ( !compareUnitCustomId1 ) {
													setCompareUnitCustomId1( dataUnit.custom_id );
													return;
												}
												if ( !compareUnitCustomId2 ) {
													setCompareUnitCustomId2( dataUnit.custom_id );
													return;
												}
											}}
											isActive={( compareUnitCustomId1 === dataUnit.custom_id || compareUnitCustomId2 === dataUnit.custom_id )}

											contentInactive={'+'}
											contentActive={<BiCheck />}

											{...props.unitCardActionButtonProps}
										/>
									}

									onClickLeftButton={() => setIsUnitDetailOpen( true )}
									toggleVirtualTourPaneFcn={
										( props.isToUseEmbeddedVirtualTour && toggleIsVirtualTourOpen ) ||
										props.toggleIsVirtualTourOpen
									}

									isToAllowSelection={props.isToAlwaysAllowSelection}
									selectedUnitId={selectedUnitId}
									setSelectedUnitId={setSelectedUnitId}
									setSelectedUnitData={props.setSelectedUnitData}
								/>
							)}
						</UnitCardsParent>
					</>
					:
					<UnitCardsParent
						fullItemsWrapperClassName={classes.tableWrapper}
						childWrapperClassName={classes.tableItemRowsWrapper}
						childClassName={classes.tableItemRow}
						unitFilterViewMode={unitFilterViewMode}
						setUnitFilterViewMode={setUnitFilterViewMode}
						unitTableItemColumnsToDisplay={SiteConfigData.unitTableItemColumns}
						isToAllowCompare={props.isToAllowCompare}

						doSelectFirstItem={true}
						selectedUnitId={selectedUnitId}
						setSelectedUnitId={setSelectedUnitId}
						setSelectedUnitData={props.setSelectedUnitData}

						dualPaneLeftPane={props.isToShowDualPaneForTableDisplay && (
							<div className={classes.listModeUnit}>
								{props.selectedUnitData && <UnitDetailPane
									dataUnit={props.selectedUnitData}

									setSelectedUnitId={setSelectedUnitId}
									toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
								/>}
							</div>
						)}
					// dontShowFilterSort
					>
						{( className, dataUnit, itemIdx, isFirstItem, toggleSavedHome ) => (
							// <UnitDetailCompareCard
							<UnitDetailTableItem
								className={className}
								dataUnit={dataUnit}
								itemIdx={itemIdx}
								isFirstItem={isFirstItem}
								toggleSavedHome={toggleSavedHome}
								unitTableItemColumnsToDisplay={SiteConfigData.unitTableItemColumns}

								unitFilterViewMode={unitFilterViewMode}

								isToAllowSelection={true}
								selectedUnitId={selectedUnitId}
								setSelectedUnitId={setSelectedUnitId}
								setSelectedUnitData={props.setSelectedUnitData}
							/>
						)}
					</UnitCardsParent>
				}
			</motion.div>
			{props.isToUseEmbeddedVirtualTour &&
				<VirtualTour
					isOpen={isVirtualTourOpen}
					src={siteConfiguration.data && siteConfiguration.data.virtual_tour_iframe_url}
					toggleVirtualTourFcn={() => toggleIsVirtualTourOpen()}
					doUseHeader={true}
				/>
			}
		</>
	)
}



const FloorplansGeneric = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles( props );

	// console.log( "floorplans", data );

	return RenderFloorplansGeneric( classes, null, null, isOpen, toggleIsOpen, null, props );

}



FloorplansGeneric.defaultProps = {
	isToAllowCompare: true,
	isToAlwaysAllowSelection: false,
	isToShowDualPaneForTableDisplay: true,
	isToUseEmbeddedVirtualTour: true,

	overrideBackgroundColor: null,
};



export default FloorplansGeneric;