import React, {
	useMemo,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
	AnimatePresence,
} from "framer-motion";


import ButtonCircle from "./ButtonCircle";


import ThemeData from "data/ThemeData";



const jsStyles = {
	wrapper: {
		textAlign: 'left',
	},
	actionButton: {
		position: 'absolute',
		top: 16,
		right: 20,

		fontSize: 32,
		lineHeight: 0,
	},
};
const useStyles = createUseStyles(
	jsStyles,
	{ name: "UnitCardActionButton" }
)



const UnitCardActionButton = ( { data, className, ...props } ) => {

	const classes = useStyles();


	const isActive = useMemo( () => {
		if ( props.isActiveFcn == null )
			return props.isActive;
		
		return props.isActiveFcn( props.dataUnit[props.onClickDataUnitArgName] );
	}, [props.isActive, props.isActiveFcn, props.dataUnit, props.onClickDataUnitArgName])


	return (
		<div className={`${classes.wrapper} ${className}`}>
			<AnimatePresence>
				{props.isToShow &&
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<ButtonCircle
							className={classes.actionButton}
							// src={plusIcon}
							alt={props.altText}
							widthHeight={40}
							iconWidthHeight={20}
							textColour={
								isActive ?
									props.textColourActive :
									props.textColourInactive
							}
							backgroundColour={
								isActive ?
									props.backgroundColourActive :
									props.backgroundColourInactive
							}
							borderColour={props.borderColour}
							onClick={( props.dataUnit && props.onClickDataUnitArgName ) ?
								() => props.onClick( props.dataUnit[props.onClickDataUnitArgName] ) :
								props.onClick
							}
						>
							{isActive ? props.contentActive : props.contentInactive}
						</ButtonCircle>
					</motion.div>
				}
			</AnimatePresence>
		</div>
	);
}



UnitCardActionButton.defaultProps = {
	isToShow: true,
	altText: "",

	textColourInactive: ThemeData.colours.primaryAccent,
	textColourActive: ThemeData.colours.primaryFontHighestContrast,
	backgroundColourInactive: ThemeData.colours.cardBg,
	backgroundColourActive: ThemeData.colours.primaryAccent,
	borderColour: ThemeData.colours.grey25,

	onClick: () => { },
	isActive: false,

	contentInactive: "-",
	contentActive: "+",
};



export default UnitCardActionButton;