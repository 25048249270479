import React, {
	useState,
	useEffect,
	useRef,
	// useContext,
} from "react";

import {
	Route,
	Switch,
	useLocation,
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import {
	AnimatePresence
} from "framer-motion";

import "mapbox-gl/dist/mapbox-gl.css";


import ThemeData from "./data/ThemeData";
import SlugData from "./data/SlugData";

import Section from "./components/Section";
import Proximation from "./components/Proximation";
import UnitDetail from "components/UnitDetail";

import useOpenClosePane from "hooks/useOpenClosePane";

import UnitFcnContext from "contexts/UnitFcnContext";

import proximaNova from './fonts/ProximaNovaRegular.otf'
import proximaNova2 from './fonts/ProximaNovaBold.otf'


const useStyles = createUseStyles(
	{
		'@font-face': [
			{
				fontFamily: 'ProximaNova-Regular',
				src: [`url( '${proximaNova}' ) format( 'woff' )`, `url( '${proximaNova}' ) format( 'woff2' )`],
				fontWeight: 'normal',
				style: 'normal',
			},
			{
				fontFamily: 'ProximaNova-Semibold',
				src: [`url( '${proximaNova2}' ) format( 'woff' )`, `url( '${proximaNova2}' ) format( 'woff2' )`],
				fontWeight: 'normal',
				style: 'normal',
			},
		],
		root: {
			height: '100%',

			color: ThemeData.colours.primaryFont,
			// backgroundColor: ThemeData.colours.primaryBg,
			// V on E49 colour
			backgroundColor: ThemeData.colours.prussianBlue,


			'--header-height': '100px',
		},
	},
	{ name: 'Root' },
)

function Root() {

	const location = useLocation();

	const classes = useStyles();

	const proxima = useRef( null );
	const proximaContainer = useRef( null );
	const [isProximaLoaded, setIsProximaLoaded] = useState( false );

	// useEffect( () => {
	// 	console.log( 'prox:', proxima );
	// }, [proxima] );
	// useEffect( () => {
	// 	console.log( 'proxCon:', proximaContainer );
	// }, [proximaContainer] );


	const [selectedUnitId, setSelectedUnitId] = useState( -1 );
	const {
		isUnitDetailOpen,
		setIsUnitDetailOpen,
		toggleIsUnitDetailOpen,
	} = useOpenClosePane( 'UnitDetail' );

	const unitFcns = {
		selectedUnitId,
		setSelectedUnitId,

		isUnitDetailOpen,
		setIsUnitDetailOpen,
		toggleIsUnitDetailOpen,
	};

	return (
		<UnitFcnContext.Provider value={unitFcns}>
			<div className={`${classes.root}`}>
				<AnimatePresence>
					<Switch location={location} key={location.pathname}>
						<Route path="/proximation">
							<Proximation
								proxima={proxima}
								proximaContainer={proximaContainer}
								isProximaLoaded={isProximaLoaded}
								setIsProximaLoaded={setIsProximaLoaded}

								// {...unitFcns}
							/>
						</Route>
						<Route path="/:slug">
							<Section
								{...unitFcns}
							/>
						</Route>
						{/* <Route
					path="/about"
					component={( props ) => (
						<Section slug="about" {...props} />
					)} />
				<Route
					path="/neighbourhood"
					component={( props ) => (
						<Section slug="neighbourhood" {...props} />
					)} />
				<Route
					path="/floorplans"
					component={( props ) => (
						<Section slug="floorplans" {...props} />
					)} />
				<Route
					path="/amenities"
					component={( props ) => (
						<Section slug="amenities" {...props} />
					)} />					<Route
					path="/showhomes"
					component={( props ) => (
						<Section slug="showhomes" {...props} />
					)} />
				<Route
					path="/gallery"
					component={( props ) => (
						<Section slug="gallery" {...props} />
					)} /> */}
						{/* <Route path="/gallery"> */}
						{/* <Route path="/thing"> */}
						{/* <div className="App">
						<header className="App-header">
							<img src={logo} className="App-logo" alt="logo" />
							<p>
								Edit <code>src/App.js</code> and save to reload.
							</p>
							<a
								className="App-link"
								href="https://reactjs.org"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn React
							</a>
						</header>
					</div>
				</Route> */}
						<Route path="/">
							<Section sectionSlug={SlugData.HOME_SLUG_NAME} />
						</Route>
						<Route path="/404.html">
							<div>
								<h2>404 Error</h2>
								<p>The page you are trying to reach is not here.</p>
							</div>
						</Route>
						{/* <Route path="/about">
					<div>
						<Nav navLocation="bottomNav" />
					</div>
				</Route> */}
					</Switch>
				</AnimatePresence>
				{/* <header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Edit <code>src/App.js</code> and save to reload.
				</p>
				<a
					className="App-link"
					href="https://reactjs.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn React
				</a>
			</header> */}
				<UnitDetail
					unitId={selectedUnitId}
					isOpen={isUnitDetailOpen}
					toggleUnitDetailFcn={() => toggleIsUnitDetailOpen()}
				/>
			</div>
		</UnitFcnContext.Provider>
	);
}

export default Root;
