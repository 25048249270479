// import logo from './logo.svg';
// import './App.css';

import React, {
	useState,
} from "react";

import {
	useLocation,
	// Switch,
	// Route,
	// useHistory
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion,
} from "framer-motion";


import Nav from './Nav';
import HeaderContainer from './HeaderContainer';
import Footer from './Footer';


import SavedHomesSlideout from "./SavedHomesSlideout";
import SavedHomesCompare from "./SavedHomesCompare";
import Register from "./Register";
import useSiteConfiguration from "hooks/useSiteConfiguration";


import SlugData from '../data/SlugData';
import ThemeData from 'data/ThemeData';

// import pageBg from "../img/overview_bg_crop4.jpg";



const useStyles = createUseStyles(
	{
		wrapperWrapper: {
			height: '100%',
		},
		wrapper: {
			position: 'relative',

			height: '100%',

			display: 'flex',
			flexDirection: 'column',

			// color: '#EEEDE6',
			fontFamily: ThemeData.fonts.titleSub,
		},
		bgBlack: {
			backgroundColor: 'black',
		},
		bgWrapper: {
			height: '100%',

			// backgroundImage: `url( ${pageBg} )`,
			backgroundImage: `url( https://storage.googleapis.com/pxa-vone49-prod/2022_06_06_2_Hero_4_K_FHR_4ae62bec72/2022_06_06_2_Hero_4_K_FHR_4ae62bec72.jpg )`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: 'cover',
		},
		bgDarker: {
			opacity: .5,
		},
		bgNone: {
			backgroundImage: 'none',
		},
		equalSized: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		middle: {
			flex: '1 1 auto',
		},
	},
	{
		name: 'Layout',
	}
);



function RenderLayout( data, props, children ) {


	const classes = useStyles();
	const location = useLocation();
	const currentLocationPathname = SlugData.determineCurrentLocation( location.pathname );

	const siteConfiguration = useSiteConfiguration();


	// const [homeBgImgUrl, setHomeBgImgUrl] = useState( null );

	const [isSavedHomesSlideoutOpen, setIsSavedHomesSlideoutOpen] = useState( false );
	const toggleIsSavedHomesSlideoutOpen = () => setIsSavedHomesSlideoutOpen( !isSavedHomesSlideoutOpen );

	const [isSavedHomesCompareOpen, setIsSavedHomesCompareOpen] = useState( false );
	const toggleIsSavedHomesCompareOpen = () => setIsSavedHomesCompareOpen( !isSavedHomesCompareOpen );

	const [isRegisterOpen, setIsRegisterOpen] = useState( false );
	const toggleIsRegisterOpen = () => setIsRegisterOpen( !isRegisterOpen );
	const [isSendToEmailOpen, setIsSendToEmailOpen] = useState( false );
	const toggleIsSendToEmailOpen = () => setIsSendToEmailOpen( !isSendToEmailOpen );

	return (
		<div className={classes.wrapperWrapper}>
			<div className={`${classes.equalSized} ${classes.bgBlack}`} />
			<div className={`${classes.equalSized} ${classes.bgWrapper} ${currentLocationPathname !== SlugData.HOME_SLUG_NAME ? classes.bgNone : ""}`} />
			{/* ${currentLocationPathname !== SlugData.HOME_SLUG_NAME ? classes.bgNone : ""} */}
			<div className={`${classes.equalSized} ${classes.bgWrapper} ${currentLocationPathname !== SlugData.HOME_SLUG_NAME ? classes.bgDarker : ""}`} />
			<div className={`${classes.wrapper}`}>
				<HeaderContainer
					toggleIsSavedHomesSlideoutOpen={() => toggleIsSavedHomesSlideoutOpen()}
					toggleIsRegisterOpen={() => toggleIsRegisterOpen()}
				/>
				<div className={classes.middle}>
					{children}
				</div>
				<Footer>
					<Nav navLocation="bottomNav" />
				</Footer>
				<SavedHomesSlideout
					isOpen={isSavedHomesSlideoutOpen}
					toggleIsOpen={() => toggleIsSavedHomesSlideoutOpen()}
					toggleIsSavedHomesCompareOpen={() => toggleIsSavedHomesCompareOpen()}
					toggleIsRegisterOpen={toggleIsSendToEmailOpen}
				/>
				<SavedHomesCompare
					isOpen={isSavedHomesCompareOpen}
					toggleIsOpen={() => toggleIsSavedHomesCompareOpen()}
					toggleIsSavedHomesSlideoutOpen={() => toggleIsSavedHomesSlideoutOpen()}
					toggleIsRegisterOpen={toggleIsSendToEmailOpen}
				/>
				{/* <Register
					data={data}
					doShow={isRegisterOpen}
					toggleIsOpen={() => toggleIsRegisterOpen()}
					titleText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_register_title_text}
					buttonText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_register_button_text}
				/> */}
				<Register
					data={data}
					doShow={isSendToEmailOpen}
					toggleIsOpen={() => toggleIsSendToEmailOpen()}
					titleText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_floorplans_title_text}
					buttonText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_floorplans_button_text}
				/>
			</div>
		</div>
	);
}



function Layout( { children, ...props } ) {

	let data = null;

	return RenderLayout( data, props, children );

}



export default Layout;
