import React, {
	// useState
} from "react";
import { createUseStyles } from "react-jss";


// import { useQuery, gql } from "@apollo/client";

// import {
// 	motion,
// } from "framer-motion";


// import Nav from "./Nav";
import ThemeData from "../data/ThemeData";


const useStyles = createUseStyles( {
	footer: {
		height: 100,

		backgroundColor: ThemeData.colours.primaryBgTransparent,

		borderTop: `1px ${ThemeData.colours.thinBorder} solid`,
	}
} );

const Footer = ( { children } ) => {
	
	// const [isOpen, toggle] = useState( false );
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			{/* <div className={classes.mainMenuButton} onClick={() => toggle( !isOpen )}>≡⚞ CLOSE MENU</div> */}
			{children}
		</footer>
	)

};


export default Footer;