import React, {
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { sendGoogleAnalyticsEvent } from 'util/sendGoogleAnalyticsEvent';
import { useLocation } from 'react-router-dom';


import ThemeData from "data/ThemeData";



const useStyles = createUseStyles(
	{
		wrapper: {
			textAlign: 'left',
		},
		list: {
			color: ThemeData.colours.secondaryFont,
			// backgroundColor: ThemeData.colours.secondaryBg,

			// VonE49 colour
			backgroundColor: ThemeData.colours.pearlWhite,

			// border: `1px ${ThemeData.colours.black} solid`,
		},
	},
	{
		name: "InputCheckedList",
	}
)



// data 					=== any data with an id field
// props.listLabelField 	=== name of the field to use as the listItem's label

const InputCheckedList = ( { data, className, ...props } ) => {
	const location = useLocation();
	const classes = useStyles();

	const [checked, setChecked] = useState( [0] );


	const handleToggle = ( listItemId ) => () => {
		const currentIndex = checked.indexOf( listItemId );
		const newChecked = [...checked];

		sendGoogleAnalyticsEvent(
			`Filter Button Click, ${location.pathname}`,
			`User Clicks Filter - ${props.filterType}, ${props.name}`,
			`Filter - ${props.filterType} - ${props.name}`,
			`${props.filterType} - ${props.name}`
		)

		if ( currentIndex === -1 ) {
			newChecked.push( listItemId );
		} else {
			newChecked.splice( currentIndex, 1 );
		}

		setChecked( newChecked );


		// const thisListItemIdx = data.indexOf( listItemId );
		// console.log( 'filterType', props.filterType, 'cListdata', data );
		// console.log( data, data[listItemId] );

		// if ( currentIdx === -1 ) {
		// 	// this is an error
		// 	// TODO: Figure out safeguarded implementation for checking if this path ever happens
		// } else {
		// 	// if ( !data[currentIdx].hasOwnProperty( props.listItemCheckedField ) )
		// 	// 	data[currentIdx][props.listItemCheckedField] = true;
		// 	// else
		// 		data[currentIdx][props.listItemCheckedField] = !data[currentIdx][props.listItemCheckedField];
		// }
		const newFilterSettings = { ...props.filterSettings };
		// if ( thisListItemIdx === -1 ) {
		// 	// this is an error
		// 	// TODO: Figure out safeguarded implementation for checking if this path ever happens
		// } else {
		if ( !newFilterSettings.hasOwnProperty( props.filterType ) )
			newFilterSettings[props.filterType] = {};
		if( !newFilterSettings[props.filterType].hasOwnProperty( listItemId ) )
			newFilterSettings[props.filterType][listItemId] = {
				// [listItemId]: {
					doFilterByOr: true,
				// },
			};
		// if ( !newFilterSettings[currentIdx].hasOwnProperty( props.listItemCheckedField ) )
		// 	data[currentIdx][props.listItemCheckedField] = true;
		// else
		// 	data[thisListItemIdx][props.listItemCheckedField] = !data[thisListItemIdx][props.listItemCheckedField];
		else 
			newFilterSettings[props.filterType][listItemId].doFilterByOr = !newFilterSettings[props.filterType][listItemId].doFilterByOr;

		props.setFilterSettings( newFilterSettings );
		// }
	};

	// console.log( "icl.data", data, "icl.props", props );

	return (
		<div className={`${classes.wrapper} ${className}`}>
			{/* {data && data.unit_number} */}
			<List
				className={classes.list}
				sx={{
					width: '100%',
					maxWidth: 360,
					// bgcolor: ThemeData.colours.primaryBg
				}}
			>
				{data.map( ( listItemData ) => {
					// console.log( 'icl.lid', listItemData );
					const labelId = `checkbox-list-label-${listItemData.id}`;

					return (
						<ListItem
							key={listItemData.id}
							// className={classes.list}
							// secondaryAction={
							// 	<IconButton edge="end" aria-label="comments">
							// 		<CommentIcon />
							// 	</IconButton>
							// }
							disablePadding
						>
							<ListItemButton role={undefined}
								onClick={handleToggle( listItemData.id )}
								dense
							>
								<ListItemIcon>
									<Checkbox
										sx={{
											color: ThemeData.colours.secondaryFont,
											'&.Mui-checked': {
												color: ThemeData.colours.secondaryFont,
											},
										}}
										edge="start"
										// checked={checked.indexOf( listItemData.id ) !== -1}
										checked={
											( props.filterSettings &&
											props.filterSettings[props.filterType] &&
											props.filterSettings[props.filterType][listItemData.id] ) !== undefined && 
											props.filterSettings[props.filterType][listItemData.id].doFilterByOr}
										tabIndex={-1}
										disableRipple
										inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={`${listItemData[props.listLabelField]}`}
									primaryTypographyProps={{
										fontFamily: 'inherit',
									}}
								/>
							</ListItemButton>
						</ListItem>
					);
				} )}
			</List>
		</div>
	);
}



export default InputCheckedList;