import React, {
	// useState,
	// useRef,
} from "react";

// import {
// 	useQuery,
// 	gql,
// } from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import HeaderSimple from "./HeaderSimple";


import ThemeData from "../data/ThemeData";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			position: 'fixed',
			top: props.doUseHeader ? 0 : 'var( --header-height )',
			left: 0,
			right: 0,

			width: '100%',
			// height: '100%',
			height: 'calc( 100vh - var( --header-height ) )',


		} ),
		genericContainer: {
			width: '100%',
			height: '100%',
			// paddingTop: 100,

			backgroundColor: ThemeData.colours.secondaryBg,
		},
	},
	{
		name: "SlideUpGenericHolder",
	}
)



const wrapperAnimVariants = {
	open: {
		y: 0,
		zIndex: 6,
		display: 'block',
	},
	closed: {
		y: "100vh",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}

function RenderSlideUpGenericHolder( classes, error, children, isOpen, toggleSlideUpIsOpen, src, props ) {

	// console.log( 'ViewStudy isOpen', isOpen );

	// const apiUri = NetData.apiUri;


	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: 'linear',
				duration: .5,
			}}
		>
			{props.doUseHeader &&
				<HeaderSimple
					backgroundColor={ThemeData.colours.primaryBg}
					onBackClick={() => toggleSlideUpIsOpen()} />
			}
			<div className={classes.genericContainer}>
				{children}
			</div>
		</motion.div>
	);
}



const SlideUpGenericHolder = ( { children, slideUpIsOpen, toggleSlideUpIsOpen, src, ...props } ) => {

	const classes = useStyles( props );

	return RenderSlideUpGenericHolder( classes, null, children, slideUpIsOpen, toggleSlideUpIsOpen, src, props );

};


SlideUpGenericHolder.defaultProps = {
	doUseHeader: false,
}



export default SlideUpGenericHolder;