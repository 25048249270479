import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";

import AutoScroll32 from '@carbon/icons-react/es/watson-health/auto-scroll/32';
import scrollDown from 'img/scroll_down.svg'


// import ThemeData from "data/ThemeData";
import ConstantData from "data/ConstantData";


const jsStyles = {
	// wrapper: {
	// },
	scrollIcon: {
		position: 'absolute',
		height: '48px',
		width: '48px',
		left: '50%',
		// zIndex: 2,

		color: 'white',
	},
	horizontalScroll: {
		bottom: '110px',
		transform: 'translateX( -50% ) rotate(90deg)',
	},
	verticalScroll: {
		bottom: '120px',
		transform: 'translateX( -50% )',
	}
};
const useStyles = createUseStyles(
	jsStyles,
	{ name: "ScrollIcon" }
)



const ScrollIcon = ( { className, ...props } ) => {

	const classes = useStyles();


	return (
		// <div className={`${classes.wrapper} ${className}`}>
		// <AutoScroll32
		// 	className={
		// 		`${className
		// 		} ${classes.scrollIcon
		// 		} ${props.direction === ConstantData.scrollIconDir.horizontal ?
		// 			classes.horizontalScroll :
		// 			classes.verticalScroll
		// 		}`}
		// />
		// </div>
		
		<div>
			{props.direction === ConstantData.scrollIconDir.horizontal ?
				<AutoScroll32
					className={`${className} ${classes.scrollIcon} ${classes.horizontalScroll}`}
				/>
				:
				<img 
					src={scrollDown} 
					className={`${className} ${classes.scrollIcon} ${classes.verticalScroll}`}
				/>
			}
		</div>
	);
}



ScrollIcon.defaultProps = {
	direction: ConstantData.scrollIconDir.horizontal,
}



export default ScrollIcon;