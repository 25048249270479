import React, {
	useState,
	useEffect,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
} from "framer-motion";


import ThemeData from "data/ThemeData";
// import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";


// import UnitDetailListItem from "./UnitDetailListItem";
// import SavedHomesTitle from "./SavedHomesTitle";
// import ButtonOutline from "./ButtonOutline";
// import ButtonGroup from "./ButtonGroup";
// import UnitCardsParent from "./UnitCardsParent";
// import UnitDetailCardGeneric from "./UnitDetailCardGeneric";
import FloorplansGeneric from "./FloorplansGeneric";


import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
// import xWhiteMedium from "img/x_white_medium.svg";



const jsStyles = {
	wrapper: {
		position: "absolute",
		top: 100,
		left: 0,

		width: 850,
		height: "100%",
		boxSizing: 'border-box',
		// overflowX: 'clip',

		// padding: 25,
		paddingTop: 30,

		color: ThemeData.colours.white,
		// backgroundColor: ThemeData.colours.primaryBg,

		// VonE49 colour
		backgroundColor: ThemeData.colours.prussianBlue,

		letterSpacing: '.025em',
	},
	bgAmp: {
		zIndex: -1,
		position: 'absolute',
		left: -50,
		bottom: -100,

		color: ThemeData.colours.primaryAccent08,
		fontFamily: ThemeData.fonts.copy,
		fontSize: 500,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',

		width: '100%',
		height: '100%',
	},
	closeX: {
		position: 'absolute',
		top: 56,
		left: -220,
		display: 'flex',
		alignItems: 'center',

		height: 50,

		// backgroundColor: ThemeData.colours.primaryBg,

		// VonE49 colour
		backgroundColor: ThemeData.colours.prussianBlue,

		cursor: 'pointer',

		zIndex: -1,
	},
	openIcon: {
		flex: '0 0 auto',
		display: 'flex',
	},
	openText: {
		flex: '0 0 auto',
		paddingRight: 20,

		fontSize: 18,
		textTransform: 'uppercase',
		letterSpacing: 4,
	},
	savedHomesTitle: {
		textAlign: 'left',
	},
	top: {
		flex: '0 0 auto',

		// height: 50,
	},
	middle: {
		flex: '1 0 auto',

		maxHeight: 'calc( 100vh - 100px )',

		overflow: 'hidden',
	},
	bottom: {
		flex: '0 0 auto',

		boxSizing: 'border-box',
		paddingTop: 25,

		height: 130,

		borderTop: `1px ${ThemeData.colours.white50} solid`,
	},
	bottomButton: {
		fontSize: 14,
		letterSpacing: '.1em',
	},
};
const useStyles = createUseStyles(
	jsStyles,
	{
		name: "UnitCardsSlideout",
	},
)



const wrapperAnimVariants = {
	open: {
		x: "calc( 100vw - 850px )",
		zIndex: 4,
		// display: 'block',
	},
	closed: {
		x: "100vw",
		display: 'block',
		zIndex: 4,
		transitionEnd: {
			// zIndex: -2,
			// display: 'none'
		},
	},
}
const defaultTransition = {
	type: "tween",
	// bounce: .25,
	duration: .5,
};



const RenderUnitCardSlideout = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	if ( error ) {
		console.log( "RenderUnitCardSlideout", error.message );
	}

	function stopPropagation( e ) {
		e.stopPropagation();
	}


	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={
				defaultTransition
			}
			onMouseDown={( e ) => stopPropagation( e )}
			onMouseUp={( e ) => stopPropagation( e )}
			onWheel={( e ) => stopPropagation( e )}
		>
			<div className={classes.contentContainer}>
				<div className={classes.top}>
					<motion.div
						className={classes.closeX}
						onClick={() => toggleIsOpen()}

						animate={isOpen ? "open" : "closed"}
						variants={{
							open: {
								left: -32,
							},
							closed: {
								left: -216,
								fontFamily: ThemeData.fonts.title,
							},
						}}
						transition={defaultTransition}
					>
						<motion.div
							className={classes.openIcon}

							animate={isOpen ? "open" : "closed"}
							variants={{
								open: {
									rotate: 180,
								},
								closed: {
									rotate: 0,
								},
							}}
							transition={defaultTransition}
						>
							<img src={chevronLeftWhiteMedium} alt={"Left Chevron"} />
						</motion.div>
						<motion.div
							className={classes.openText}
						>
							Floor Plans
						</motion.div>
					</motion.div>
				</div>
				<div className={classes.middle}>
					<FloorplansGeneric
						isToAllowCompare={false}
						isToShowDualPaneForTableDisplay={false}

						isToAlwaysAllowSelection={true}
						selectedUnitId={props.selectedUnitId}
						setSelectedUnitId={props.setSelectedUnitId}
						selectedUnitData={hooks.selectedUnitData}
						setSelectedUnitData={hooks.setSelectedUnitData}

						isToUseEmbeddedVirtualTour={false}
						toggleIsVirtualTourOpen={props.toggleIsVirtualTourOpen}

						cardOnClickLeftButton={props.cardOnClickLeftButton}
					/>
				</div>
			</div>
		</motion.div>
	)
}



const UnitCardSlideout = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	const [selectedUnitData, setSelectedUnitData] = useState( null );

	let hooks = {
		savedHomes,
		toggleSavedHome,
		selectedUnitData,
		setSelectedUnitData,
	};


	useEffect( () => {
		// console.log( 'ucsSelUnitId', props.selectedUnitId );
	}, [props.selectedUnitId] )


	const { loading, error, data } = useQuery( QueryData.units );

	if ( loading ) return RenderUnitCardSlideout( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderUnitCardSlideout( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	// console.log( "ucs", data );

	return RenderUnitCardSlideout( classes, data, null, isOpen, toggleIsOpen, hooks, props );

}



export default UnitCardSlideout;