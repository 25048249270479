import React, {
	useState,
	useEffect,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
	// useAnimation,
	AnimatePresence,
} from "framer-motion";

import {
	FaCaretDown,
	// FaThLarge,
} from "react-icons/fa";
import {
	BsFillGridFill
} from "react-icons/bs";
import {
	AiOutlineUnorderedList,
} from "react-icons/ai";
import {
	// HiHeart,
	// HiOutlineHeart,
} from "react-icons/hi";


import ThemeData from "data/ThemeData";
import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";
import SiteConfigData from "data/SiteConfigData";


import useSavedHomes from "hooks/useSavedHomes";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import useSiteFeatureToggles from "hooks/useSiteFeatureToggles";


// import UnitDetailListItem from "./UnitDetailListItem";
// import UnitDetailCompareCard from "components/UnitDetailCompareCard";
// import SavedHomesTitle from "components/SavedHomesTitle";
import ButtonOutline from "components/ButtonOutline";
import ButtonGroup from "./ButtonGroup";
import InputCheckedList from "components/InputCheckedList";
import InputRangeSlider from "./InputRangeSlider";


// import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
// import xWhiteMedium from "img/x_white_medium.svg";
// import iconChevronDown from "img/icon_chevron_down_filled_white.svg";
// import SavedHomesCompareList from "./SavedHomesCompareList";



const useStyles = createUseStyles(
	{
		wrapper: {
			height: '100%',

			// paddingTop: 50,
			// position: 'fixed',
			// top: '50%',
			// left: '50%',
			// paddingTop: 50,

			// width: 'calc( 100vw - 60px )',
			// height: 'calc( 100vh - 200px )',
			// boxSizing: 'border-box',
			// // overflow: 'clip',

			// // padding: '0 35px',

			// color: ThemeData.colours.white,
			// backgroundColor: ThemeData.colours.primaryBg,

			// letterSpacing: '.025em',

			'--filterHeaderHeight': '100px',
		},
		filterHeader: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: 'var( --filterHeaderHeight )',

			...ThemeData.styles.hrBoxShadow,
		},
		filterCategoryControls: {
			flex: '1 0 auto',
		},
		filterButtons: {
			height: 50,

			flex: '0 0 auto',

			padding: '0 50px',
		},
		filterButton: {
			...ThemeData.styles.roundedCorners,
		},
		filterViewControls: {
			flex: '0 0 auto',
			height: 50,
			display: 'flex',
			alignItems: 'center',

			padding: '0 50px 0 0',

			fontSize: 32,
			color: ThemeData.colours.primaryFontHighestContrast,

			'& > div': {
				flex: '0 0 auto',
				display: 'inline-block',
				marginLeft: 20,
			},
		},
		filterViewIcon: {
			cursor: 'pointer',
		},
		filterViewIconSelected: {
			// VonE49 color
			color: ThemeData.colours.pearlWhite,

			cursor: 'default',
		},
		filterList: {
			// border: ThemeData.colours.paneBorders,
			color: ThemeData.colours.primaryFont,
		},
		filterRange: {
			color: ThemeData.colours.secondaryFont,
			// backgroundColor: ThemeData.colours.secondaryBg,
			
			// VonE49 colour
			backgroundColor: ThemeData.colours.pearlWhite,
		},
		compareButton: {
			// border: `1px ${ThemeData.colours.primaryAccent} solid`,
			// VonE49 colour
			border: `1px ${ThemeData.colours.cadetBlue} solid`,

			...ThemeData.styles.roundedCorners,
		},
		wrapperSizing: {
			height: 'calc( 100% - var( --filterHeaderHeight ) )',
		},
		paneSizing: {
			height: '100%',
		},
		compareInstruction: {
			paddingBlock: 50,
			fontSize: 24,

			...ThemeData.styles.hrBoxShadow,

			transformOrigin: 'top',
		},
		fullItemsWrapper: {
			height: '100%',
			overflowY: 'auto',
			...ThemeData.styles.thinScrollbarLightOnDark,

			'&.noFilterSort': {
				maxHeight: 'calc( 100% - 50px )',
			},
		},
		dualPaneWrapper: {
			display: 'flex',
		},
		dualPanePane: {
			width: '50%',
			boxSizing: 'border-box',
		},
		dualPaneLeftPane: {
			height: '100%',
		},
		unitCards: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',
			boxSizing: 'border-box',

			paddingTop: 50,

			// maxHeight: 'calc( 100% - 145px )',

			// overflowX: 'auto',

			// ...ThemeData.styles.hideScrollbar,

		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.primaryAccent08,
			fontFamily: ThemeData.fonts.copy,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',
		},
		topRightContainer: {
			position: 'absolute',
			top: 60,
			right: 60,

			display: 'flex',
			alignItems: 'center',
		},
		closeX: {
			marginTop: 10,
			marginLeft: 50,

			cursor: 'pointer',
		},
		sendToEmailButton: {
			display: 'inline-block',

			width: 150,

			fontSize: 12,
			letterSpacing: '.1em',
		},
		savedHomesTitle: {
			textAlign: 'left',

			fontSize: 24,
			letterSpacing: '.15em',
		},
		top: {
			flex: '0 0 auto',

			// height: 50,
		},
		addHomesButton: {
			width: 220,

			fontSize: 12,
			letterSpacing: '.1em',

			borderRadius: 25,
		},
		middle: {
			flex: '1 0 auto',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',

			maxHeight: 'calc( 100vh - 200px )',

			// overflowX: 'auto',
			overflowY: 'auto',

			// ...ThemeData.styles.hideScrollbar,
			...ThemeData.styles.thinScrollbar,
		},
		unitDetailCompareCardWrapper: {
			flex: '0 0 auto',
			width: '350px',

			// width: 575,
			// height: '100%',

			padding: '0 10px 40px 20px',
		},
		unitCard: {
			color: ThemeData.colours.black,
		},
		tableHeaderRow: {
			height: 50,
			display: 'flex',
			alignItems: 'center',

			color: ThemeData.colours.primaryFont,
			fontFamily: ThemeData.fonts.title,
		},
		tableHeaderItem: {
			flex: '1 0 auto',
			width: 100,
		},

		bottom: {
			flex: '0 0 auto',
			display: 'flex',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
	},
	{
		name: "UnitCardsParent",
	},
)


/**
 * A filterType of 
 *  - 'checkedList' requires a unitRelationDataType,
 *  - 'range' requires a unitField
 */

const filterableSources = [
	{
		label: 'Rooms',

		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name',
	},
	{
		label: 'Floors',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitFloors',
		dataListItemLabelField: 'name',
	},
	// {
	// 	buttonLabel: 'Orientation',
	// 	filterType: ConstantData.unitFilterTypes.checkedList,
	// 	unitRelationDataType: 'unitExposures',
	// 	dataListItemLabelField: 'direction',
	// },
	{
		label: 'Building',

		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitBuildings',
		dataListItemLabelField: 'name',
	},
	// {
	// 	label: 'Price',

	// 	filterType: ConstantData.unitFilterTypes.range,
	// 	unitField: 'price',
	// 	rangeMinimumValue: 800000,
	// 	rangeMaximumValue: 2500000,
	// 	rangeStep: 100000,
	// 	displayedDataFormattingFunction: ( value ) => {
	// 		return `$${value / 1000}K`
	// 	},
	// },
	{
		label: 'Size',

		filterType: ConstantData.unitFilterTypes.range,
		unitField: 'size',
		rangeMinimumValue: 480,
		rangeMaximumValue: 915,
		rangeStep: 10,
		displayedDataFormattingFunction: ( value ) => {
			return `${value} sqft`
		},
	},
	{
		label: 'Availability',

		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitAvailabilityStates',
		dataListItemLabelField: 'name',
	},
];



const RenderUnitCardsParent = ( classes, data, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props ) => {

	if ( error ) {
		console.log( "RenderFloorplans", error.message );
	}

	// console.log( 'fud', filteredUnitsData );
	// console.log( 'fcn', props.filterClassName );
	// console.log( 'chill', props.children );

	return (
		<div className={classes.wrapper}>
			{( !props.dontShowFilter && !props.dontShowFilterSort ) &&
				<div className={classes.filterHeader}>
					<div className={classes.filterCategoryControls}>
						<ButtonGroup
							className={classes.filterButtons}
							justifyContent={'left'}
						>
							{/* <HiHeart />
							<HiOutlineHeart /> */}
							{filterableSources.map( ( filterableSource, idx ) => (
								<ButtonOutline
									key={idx}

									textColour={ThemeData.colours.secondaryFont}
									hoverTextColour={ThemeData.colours.pearlWhite}
									backgroundColour={ThemeData.colours.pearlWhite}
									hoverBackgroundColour={ThemeData.colours.tealBlue}
									borderColour={ThemeData.colours.eggshellTextBg}
									className={classes.filterButton}
									// onClick={() => {
									// }}
									popupWindow={data &&
										<div className={classes.filterList}>
											{filterableSource.filterType === ConstantData.unitFilterTypes.checkedList &&
												<InputCheckedList
													className={props.filterClassName}

													data={data[filterableSource.unitRelationDataType]}
													listLabelField={filterableSource.dataListItemLabelField}
													filterType={filterableSource.unitRelationDataType}

													filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
													setFilterSettings={hooks.globalUnitsFilter.setFilter}
												/>}
											{filterableSource.filterType === ConstantData.unitFilterTypes.range &&
												<InputRangeSlider
													className={classes.filterRange}

													label={filterableSource.label}
													filterType={filterableSource.unitField}
													min={filterableSource.rangeMinimumValue}
													max={filterableSource.rangeMaximumValue}
													step={filterableSource.rangeStep}
													displayedDataFormattingFunction={filterableSource.displayedDataFormattingFunction}

													filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
													setFilterSettings={hooks.globalUnitsFilter.setFilter}
												/>
											}
										</div>
									}
									firstInColl={idx === 0 ? "true" : null}
								>
									{filterableSource.label}&nbsp;<FaCaretDown />
								</ButtonOutline>

							) )}
						</ButtonGroup>
					</div>
					<div className={classes.filterViewControls}>
						<div className={
							`${classes.filterViewIcon
							} ${classes.controlIconGrid
							} ${props.unitFilterViewMode === ConstantData.unitFilterViewModes.card ? classes.filterViewIconSelected : ''
							}`}
							onClick={() => props.setUnitFilterViewMode( ConstantData.unitFilterViewModes.card )}
						>
							<BsFillGridFill />
						</div>
						<div className={
							`${classes.filterViewIcon
							} ${classes.controlIconList
							} ${props.unitFilterViewMode === ConstantData.unitFilterViewModes.list ? classes.filterViewIconSelected : ''
							}`}
							onClick={() => props.setUnitFilterViewMode( ConstantData.unitFilterViewModes.list )}
						>
							<AiOutlineUnorderedList />
						</div>
						{props.isToAllowCompare &&
							<ButtonOutline
								className={classes.compareButton}
								backgroundColour={
									props.unitFilterViewMode === ConstantData.unitFilterViewModes.compare ?
										ThemeData.colours.tealBlue :
										null
								}
								onClick={() => props.setUnitFilterViewMode( ConstantData.unitFilterViewModes.compare )}
							>
								COMPARE
							</ButtonOutline>}
					</div>
				</div>
			}


			<AnimatePresence>
				{props.unitFilterViewMode === ConstantData.unitFilterViewModes.compare &&
					!props.compareUnitCustomId1 && !props.compareUnitCustomId2 &&
					<motion.div
						className={classes.compareInstruction}
						// initial={{ scale: 0, opacity: 0 }}
						// animate={{ scale: 1, opacity: 1 }}
						// exit={{ scale: 0, opacity: 0 }}
						{...ThemeData.anim.defaultOpacityFade}
					// transition={{ type: "linear", duration: .25 }}
					>
						Begin By Selecting A Plan
					</motion.div>
				}
			</AnimatePresence>
			<div className={
				`${classes.wrapperSizing
				} ${props.dualPaneLeftPane && classes.dualPaneWrapper
				}`}
			>
				<AnimatePresence
				// exitBeforeEnter={true}
				>
					{props.dualPaneLeftPane &&
						<motion.div
							className={
								`${props.dualPaneLeftPane && classes.dualPanePane
								}`}
							key={1}
							{...ThemeData.anim.defaultOpacityFade}
						>
							{/* <AnimatePresence exitBeforeEnter={true}> */}
							{props.dualPaneLeftPane}
							{/* </AnimatePresence> */}
						</motion.div>
					}
					<motion.div
						className={
							`${classes.paneSizing
							} ${props.dualPaneLeftPane && classes.dualPanePane
							}`}
						key={2}
						{...ThemeData.anim.defaultOpacityFade}
					>
						<AnimatePresence exitBeforeEnter={true}>
							{props.dualPaneRightPane ?
								props.dualPaneRightPane
								:
								<motion.div
									className={
										`${classes.fullItemsWrapper
										} ${props.fullItemsWrapperClassName
										} ${( !props.dontShowFilter && !props.dontShowFilterSort ) ? '' : 'noFilterSort'
										}`}
									{...ThemeData.anim.defaultOpacityFade}
								>
									{props.unitTableItemColumnsToDisplay &&
										<div className={classes.tableHeaderRow}>
											{props.unitTableItemColumnsToDisplay.map( ( unitTableItemColumn, idx ) => (
												<div
													className={classes.tableHeaderItem}
													key={idx}
												>
													{unitTableItemColumn.columnName}
												</div>
											) )}
										</div>
									}
									<div
										id='unitItemsDirectParent'
										className={
											`${classes.unitCards
											} ${props.childWrapperClassName
											}`}
									>
										<AnimatePresence>
											{props.children && data && data.units && filteredUnitsData &&
												(
													[...filteredUnitsData]
														.sort( SiteConfigData.Floorplans.orderByPredicate )
														.map( ( dataUnit, idx ) => (
															<motion.div
																unitid={dataUnit.custom_id}
																className={
																	`${props.childClassName || classes.unitDetailCompareCardWrapper
																	}`}
																key={dataUnit.id}
																custom={dataUnit.id}
																{...ThemeData.anim.defaultOpacityFade}
																layout="position"
															>
																<div
																	unitid={dataUnit.custom_id}
																/>
																{props.children(
																	classes.unitCard,
																	dataUnit,
																	idx,
																	idx === 0,
																	() => hooks.toggleSavedHome( dataUnit.id )
																)}
															</motion.div>
														) )
												)
											}
											{props.children && data && data.units && filteredUnitsData && filteredUnitsData.length === 0 &&
												<motion.div
													className={classes.filterProducesNoResultsNotice}
													key={-1}
													layout="position"
												>
													No homes found with the selected filters.
												</motion.div>
											}
										</AnimatePresence>
									</div>
									{/* :
						<div className={`${classes.unitCards} ${( !props.dontShowFilter && !props.dontShowFilterSort ) ? '' : 'noFilterSort'}`}>
							list view incoming

						</div> */}
									{/* } */}
								</motion.div>
							}
						</AnimatePresence>
					</motion.div>
				</AnimatePresence>
			</div>
		</div >
	)
}



const UnitCardsParent = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	// const [filterSettings, setFilterSettings] = useState( {} );

	const globalUnitsFilter = useGlobalUnitsFilter();

	const siteFeatureToggles = useSiteFeatureToggles();

	// const cardAnimControls = useAnimation();

	// const [viewMode, setViewMode] = useState( ConstantData.unitFilterViewModes.card );


	let hooks = {
		savedHomes,
		toggleSavedHome,
		// filterSettings,
		// setFilterSettings,
		globalUnitsFilter,
		siteFeatureToggles,
		// viewMode,
		// setViewMode,
	};

	// console.log( "initfs", filterSettings );
	// console.log( "ucpProps", props );


	const { loading, error, data } = useQuery( QueryData.unitProperties );


	const [filteredUnitsData, setFilteredUnitsData] = useState( [] );


	useEffect( () => {
		// console.log( 'ucpSelUnitId', props.selectedUnitId );
		// console.log( 'ucpSetSelUnit', props.setSelectedUnitId );
	}, [props.selectedUnitId, props.setSelectedUnitId] )


	useEffect( () => {
		// console.log( 'filterSettingsChanged', globalUnitsFilter );

		if ( data ) {
			// console.log( "UNfiltereddata", data.units );
			setFilteredUnitsData( globalUnitsFilter.applyFilter( data.units ) );

			// console.log( "Filteredata:", filteredUnitsData );

		}
	}, [globalUnitsFilter.globalUnitsFilter, data] );

	useEffect( () => {
		if ( props.doSelectFirstItem ) {
			if (
				!props.selectedUnitId ||
				( filteredUnitsData.length > 0 &&
				filteredUnitsData.filter( unitData => unitData.custom_id === props.selectedUnitId ).length === 0 )
			) {
				props.setSelectedUnitId( filteredUnitsData[0].custom_id );
				return;
			}

		}
	} );

	useEffect( () => {
		if ( props.setDataUnitCustomId1 ) {
			// console.log( 'ucpSetData1', props.compareUnitCustomId1, data.units );
			// console.log( 'fit', data.units.filter( dataUnit => dataUnit.custom_id === props.compareUnitCustomId1 )[0] );
			if ( data && props.compareUnitCustomId1 ) {
				props.setDataUnitCustomId1( data.units.filter( dataUnit => dataUnit.custom_id === props.compareUnitCustomId1 )[0] );
				return;
			}

			props.setDataUnitCustomId1( null );
		}
	}, [props.compareUnitCustomId1, data] );
	useEffect( () => {
		if ( props.setDataUnitCustomId2 ) {
			// console.log( 'ucpSetData1', props.compareUnitCustomId1, data.units );
			// console.log( 'fit', data.units.filter( dataUnit => dataUnit.custom_id === props.compareUnitCustomId1 )[0] );
			if ( data && props.compareUnitCustomId2 ) {
				props.setDataUnitCustomId2( data.units.filter( dataUnit => dataUnit.custom_id === props.compareUnitCustomId2 )[0] );
				return;
			}

			props.setDataUnitCustomId2( null );
		}
	}, [props.compareUnitCustomId2, data] );

	/** 
	 * - Scroll Unit card selected in WebGL into view if not already in view
	 * - Animate card
	 */
	useEffect( () => {
		if ( props.setSelectedUnitData )
			props.setSelectedUnitData( filteredUnitsData.filter( unitData => unitData.custom_id === props.selectedUnitId )[0] );

		if ( props.selectedUnitId && props.selectedUnitId !== -1 ) {
			// console.log( 'ucpSuiid', props.selectedUnitId, 'thin', document.getElementById( 'unitItemsDirectParent' ) );
			// scroll item into view

			let directParentElm = document.getElementById( 'unitItemsDirectParent' );
			if ( !directParentElm ) return;

			let unitItemElm = directParentElm.querySelector( `[unitId='${props.selectedUnitId}']` );
			if ( !unitItemElm ) return;

			unitItemElm.parentElement.scrollIntoView( {
				behavior: 'smooth',
				block: 'nearest',
			} );
		}
	}, [props.selectedUnitId] );


	if ( loading ) return RenderUnitCardsParent( classes, null, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderUnitCardsParent( classes, null, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props );

	// console.log( "ucp", data );

	return RenderUnitCardsParent( classes, data, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props );

}



UnitCardsParent.defaultProps = {
	dontShowFilterSort: false,
	dontShowFilter: false,
	dontShowSort: false,
	doSelectFirstItem: false,
	isToAllowCompare: true,
}



export default UnitCardsParent;