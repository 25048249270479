import React from "react";

import {
	createUseStyles
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	HiHeart,
	HiOutlineHeart,
} from "react-icons/hi";

// import createPersistedState from "use-persisted-state";


import useSavedHomes from "hooks/useSavedHomes";

import imgFunctions from "util/imgFunctions";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import ButtonCircle from "components/ButtonCircle";
import UnitInfoPlanNameTitle from "components/UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";
import UnitInfoIconList from "./UnitInfoIconList";


import ThemeData from "../data/ThemeData";
// import NetData from "../data/NetData";
// import QueryData from "../data/QueryData";
import ConstantData from "data/ConstantData";


// import saveHomePlusLight from "../img/save_home_plus_light.svg";
// import plusIcon from "img/icon_plus.svg";



// const useSavedHomesState = createPersistedState( ConstantData.localStorageKeys.savedHomes );



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',

			width: '100%',
			height: '100%',
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',

			padding: 50,

			backgroundColor: ThemeData.colours.cardBg,

			'& > div': {
				flex: '0 0 auto',
			},
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 200,
			flex: '1 1 auto !important',

			// borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
			borderBottom: `1px ${ThemeData.colours.cadetBlue} solid`,

			'& > img': {
				width: '100%',
				height: '100%',
				objectFit: 'contain',
			},
		},
		'$floorplanImage, $details': {
			// borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
			borderBottom: `1px ${ThemeData.colours.cadetBlue} solid`,
		},
		details: {
			position: 'relative',

			minHeight: 63,

			// padding: '20px 20px 5px 20px',

			textAlign: 'left',

			// '& h4': {
			// 	margin: 0,
			// 	padding: 0,

			// 	fontFamily: ThemeData.fonts.title,
			// 	fontSize: '24px',
			// },

			'& p': {
			},
		},
		hr: {
			width: '100%',

			// marginTop: 35,

			borderTop: `1px ${ThemeData.colours.lightBorderDarkOnLight} solid`,
		},
		unitInfoPlanNameTitle: {
			margin: 0,
			padding: 0,

			color: ThemeData.colours.secondaryFont,

			fontFamily: ThemeData.fonts.title,
			fontSize: '36px',
		},
		unitInfoLine: {
			marginTop: 10,

			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: '16px',
			letterSpacing: '0.05em',
		},
		unitInfoIconList: {
			color: ThemeData.colours.secondaryFont,
		},
		unitInfoList: {

		},
		actionButton: {
			position: 'absolute',
			top: 16,
			right: 20,

			fontSize: 20,
		},
		buttonArea: {
			padding: 25,
			paddingBottom: 0,
		},
		moreDetailsButtonArea: {
			borderTop: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
	},
	{
		name: "UnitDetailPane",
	}
)


// function toggleSavedHome( hooks, unitId ) {
// 	let prevUnitIdExistsIdx = hooks.savedHomes.findIndex(elm => elm.id == unitId);
// 	if (prevUnitIdExistsIdx === -1)
// 		hooks.setSavedHomes([...hooks.savedHomes, { id: unitId }]);
// 	else {
// 		let newSavedHomes = hooks.savedHomes;
// 		newSavedHomes.splice(prevUnitIdExistsIdx, 1);
// 		hooks.setSavedHomes([...newSavedHomes]);
// 	}
// }


function renderUnitDetailPane( selectedUnitId, dataUnit, classes, error, toggleUnitDetailPaneFcn, hooks, props ) {
	const doDisplayCard = true; //!( selectedUnitId === -1 );
	// doDisplayCard = true;


	// const unitFcns = {
	// 	selectedUnitId: props.selectedUnitId,
	// 	setSelectedUnitId: props.setSelectedUnitId,

	// 	isUnitDetailOpen: props.isUnitDetailOpen,
	// 	setIsUnitDetailOpen: props.setIsUnitDetailOpen,
	// 	toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
	// }


	return (
		<div className={`${props.className} ${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}>
			<div className={classes.details}>
				{props.onClickUnitActionButton &&
					<ButtonCircle
						className={classes.actionButton}
						// src={plusIcon}
						alt={props.altTextUnitActionButton}
						widthHeight={32}
						iconWidthHeight={20}
						textColour={ThemeData.colours.secondaryFont}
						backgroundColour={ThemeData.colours.cardBg}
						borderColour={ThemeData.colours.clear}

						onClick={props.onClickUnitActionButton}
						reactOnHover={false}
					>
						{props.labelUnitActionButton}
					</ButtonCircle>
				}
				{/* <h4>{dataUnit && `Plan ${dataUnit.unit_plan_type.name}`}</h4> */}
				{dataUnit && <UnitInfoPlanNameTitle
					data={dataUnit}
					// color={ThemeData.colours.secondaryTitleFont}
					fontSize={36}
					doShowUnitNumber={true}
					className={classes.unitInfoPlanNameTitle}
				/>}
				<div className={classes.unitInfoLine}>
					{dataUnit && <UnitInfoLine
						data={dataUnit}
					/>}
				</div>
			</div>
			<div className={classes.floorplanImage}>
				{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
					<img
						src={`${imgFunctions.GetImgBySizeUri( dataUnit.unit_plan_type.floorplan_image, ConstantData.imgSizes.large )} `}
						alt={dataUnit.unit_plan_type.floorplan_image.alternativeText}
					// width={331}
					// height={187}
					/>
				}
				{/* {( !dataUnit || !dataUnit.unit_plan_type || !dataUnit.unit_plan_type.floorplan_image ) &&
					<img
						src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
						alt='floorplan placeholder'
						width={331}
						height={187}
					/>
				} */}
			</div>
			<div className={classes.hr} />
			<UnitInfoIconList
				className={classes.unitInfoIconList}
				dataUnit={dataUnit}
				marginTopItemsRow={25}
			/>
			{/* <UnitInfoList
				dataUnit={dataUnit}
			/> */}
			<div className={classes.hr} />
			<div className={classes.buttonArea}>
				<ButtonGroup
					justifyContent={'center'}
				>
					<ButtonOutline
						label="Details"
						textColour={ThemeData.colours.tealBlue}
						backgroundColour={ThemeData.colours.cardBg}
						borderColour={ThemeData.colours.tealBlue}
						isRounded={true}

						to="."
						onClick={() => {
							props.setSelectedUnitId( dataUnit.custom_id );
							props.toggleIsUnitDetailOpen();
						}}
						firstInColl
					/>
					<ButtonOutline
						fontSize={30}
						textColour={ThemeData.colours.tealBlue}
						backgroundColour={ThemeData.colours.cardBg}
						hoverTextColour={ThemeData.colours.pearlWhite}
						hoverBackgroundColour={ThemeData.colours.tealBlue}
						// textColour={
						// 	hooks.savedHomesIncludes( dataUnit && dataUnit.id ) ?
						// 		ThemeData.colours.primaryFontHighestConstrast :
						// 		ThemeData.colours.primaryAccent
						// }
						// hoverTextColour={
						// 	hooks.savedHomesIncludes( dataUnit && dataUnit.id ) ?
						// 		ThemeData.colours.primaryAccent :
						// 		ThemeData.colours.primaryFontHighestConstrast
						// }
						// backgroundColour={
						// 	hooks.savedHomesIncludes( dataUnit && dataUnit.id ) ?
						// 		ThemeData.colours.primaryAccent :
						// 		ThemeData.colours.primaryFontHighestConstrast
						// }
						// hoverBackgroundColour={
						// 	hooks.savedHomesIncludes( dataUnit && dataUnit.id ) ?
						// 		ThemeData.colours.primaryFontHighestConstrast :
						// 		ThemeData.colours.primaryAccent
						// }
						// hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.tealBlue}
						isCircle={true}

						isActive={hooks.savedHomesIncludes( dataUnit && dataUnit.id )}
						onClick={() => hooks.toggleSavedHome( dataUnit && dataUnit.id )}
					>
						{hooks.savedHomesIncludes( dataUnit && dataUnit.id ) ?
							<HiHeart /> :
							<HiOutlineHeart />
						}
					</ButtonOutline>
					{/* <ButtonOutline
						label="Enjoy the View"
						// padding={}
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						to="."
						onClick={props.toggleViewStudyPaneFcn}
						firstInColl
					/>
					<ButtonOutline
						label="Take the Tour"
						// padding={20}
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						onClick={props.toggleVirtualTourPaneFcn}
						to="."
					/> */}
				</ButtonGroup>
			</div>
			{/* <div className={classes.moreDetailsButtonArea}>
				<ButtonOutline
					label="More Details"
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.eggshellTextBg}
					onClick={toggleUnitDetailPaneFcn}
				/>
			</div> */}
		</div>
	);
}


function UnitDetailPane( { dataUnit, selectedUnitId, toggleUnitDetailPaneFcn, ...props } ) {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	// console.log( "saved", savedHomes );

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
	}


	// const { loading, error, data } = useQuery( QueryData.units );


	// if ( loading ) return renderUnitDetailCardGeneric( selectedUnitId, null, classes, null, toggleUnitDetailPaneFcn, hooks, props);
	// if ( error ) return renderUnitDetailCardGeneric( selectedUnitId, null, classes, error, toggleUnitDetailPaneFcn, hooks, props );

	// console.log( 'udp', dataUnit );





	// return renderUnitDetailCardGeneric( selectedUnitId, data, classes, null, toggleUnitDetailPaneFcn, hooks, props );
	return renderUnitDetailPane( selectedUnitId, dataUnit, classes, null, toggleUnitDetailPaneFcn, hooks, props );
}



export default UnitDetailPane;