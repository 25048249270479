import React, {
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";

import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";

import ButtonGroup from "components/ButtonGroup";
import ButtonCircle from "components/ButtonCircle";
import ButtonOutline from "components/ButtonOutline";


import xWhiteSmall from "img/x_white_small.svg";
import UnitInfoIconList from "./UnitInfoIconList";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',
			// height: '100%',

			width: 575,

			...ThemeData.styles.hoverCard,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			// alignItems: 'center',

			padding: 20,

			textAlign: 'left',
		},
		hr: {
			width: '100%',

			marginTop: 35,

			borderTop: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		removeHomeButton: {
			position: 'absolute',
			top: 20,
			right: 20,

			width: 48,
		},
		floorplanContainer: {
			flex: '0 0 auto',

			// width: 105,
			height: 300,

			marginRight: 25,

			borderRadius: 4,

			// backgroundColor: ThemeData.colours.secondaryBg,
		},
		floorplanImg: {
			height: '100%',
			width: '100%',

			objectFit: 'contain',
		},
		planButtonsContainer: {
			marginTop: 10,
		},
		floorKeyPlanButtons: {
			borderRadius: 50,
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			color: ThemeData.colours.cardSubTitleFont,

			fontSize: 16,
		},
		unitInfoIconList: {
		},
		viewDetailButtonArea: {
			paddingTop: 10,
		},
	},
	{
		name: "UnitDetailCompareCard",
	}
)


// data == a single Unit
const UnitDetailCompareCard = ( { dataUnit, ...props } ) => {

	const classes = useStyles();


	const [doShowFloorplanImg, setDoShowFloorplanImg] = useState( true );
	const toggleDoShowFloorplanImg = () => setDoShowFloorplanImg( !doShowFloorplanImg );


	// console.log( 'dataUnit', dataUnit );

	return (
		<div className={`${classes.wrapper} ${props.className}`}>
			<div className={classes.contentContainer}>
				<div className={classes.removeHomeButton}>
					<ButtonCircle
						src={xWhiteSmall}
						alt="Remove This Saved Home"
						widthHeight={46}
						iconWidthHeight={26}
						borderColour={ThemeData.colours.white}
						textColour={ThemeData.colours.cadetBlue}
						backgroundColour={ThemeData.colours.primaryBg}
						onClick={() => props.toggleSavedHome()}
					/>
				</div>
				<div className={classes.info}>
					<UnitInfoPlanNameTitle
						data={dataUnit}
						color={ThemeData.colours.cardTitleFont}
						fontSize={36}
						doShowUnitNumber={true}
						className={classes.unitInfoPlanNameTitle}
					/>
					<UnitInfoLine
						data={dataUnit}
						className={classes.unitInfoLine}
						doShowFloor={true}
						doShowUnitNumber={true}
						doShowHomeType={false}
						doShowBaths={false}
						doShowIntSqft={false}
					/>
				</div>
				<div className={classes.floorplanContainer}>
					{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
						<img
							className={classes.floorplanImg}
							src={NetData.imgUri( dataUnit && dataUnit.unit_plan_type.floorplan_image.url )}
							alt={dataUnit && dataUnit.unit_plan_type.floorplan_image.alternativeText} />
					}
					{dataUnit &&
						( !dataUnit.unit_plan_type || !dataUnit.unit_plan_type.floorplan_image ) &&
						dataUnit.floorplan_image && dataUnit.floorplan_image.url &&
						<>
							{doShowFloorplanImg && dataUnit.floorplan_image && 
								<img
									className={classes.floorplanImg}
									src={NetData.imgUri( dataUnit && dataUnit.floorplan_image.url )}
									alt={dataUnit && dataUnit.floorplan_image.alternativeText} />
								// src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
								// alt={'floorplan placeholder large'} />
							}
							{!doShowFloorplanImg && dataUnit.keyplan_image && 
								<img
									className={classes.floorplanImg}
									src={NetData.imgUri( dataUnit && dataUnit.keyplan_image.url )}
									alt={dataUnit && dataUnit.keyplan_image.alternativeText} />
								// src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
								// alt={'floorplan placeholder large'} />
							}
						</>
					}
				</div>
				{/* <div className={classes.floorplanImg}>
				{data.floorplan_image && <img
					src={`${apiUri}${data.floorplan_image.formats && data.floorplan_image.formats.small.url}`}
				/>}
			</div> */}
				{dataUnit && dataUnit.floorplan_image && dataUnit.keyplan_image &&
					<div className={classes.planButtonsContainer}>
						<ButtonGroup
							justifyContent={'center'}
						>
							<ButtonOutline
								borderColour={ThemeData.colours.grey25}
								className={classes.floorKeyPlanButtons}
								textColour={ThemeData.colours.primaryAccent}
								backgroundColour={ThemeData.colours.white}
								hoverTextColour={ThemeData.colours.white}
								hoverBackgroundColour={ThemeData.colours.primaryAccent}
								firstInColl
								onClick={toggleDoShowFloorplanImg}
								isActive={doShowFloorplanImg}
							>
								Floor Plan
							</ButtonOutline>
							<ButtonOutline
								borderColour={ThemeData.colours.grey25}
								className={classes.floorKeyPlanButtons}
								textColour={ThemeData.colours.primaryAccent}
								backgroundColour={ThemeData.colours.white}
								hoverTextColour={ThemeData.colours.white}
								hoverBackgroundColour={ThemeData.colours.primaryAccent}
								onClick={toggleDoShowFloorplanImg}
								isActive={!doShowFloorplanImg}
							>
								Key Plan
							</ButtonOutline>
						</ButtonGroup>
					</div>
				}
				<div className={classes.hr} />
				<UnitInfoIconList
					className={classes.unitInfoIconList}
					dataUnit={dataUnit}
					marginTopItemsRow={25}
					doShowFacingItem={false}
				/>
			</div>
			{/* <div className={classes.viewDetailButtonArea}>
				<ButtonOutline
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.eggshellTextBg}
				>
					View Home In Detail
				</ButtonOutline>
			</div> */}
		</div>
	);
}



export default UnitDetailCompareCard;