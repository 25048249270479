import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";


// import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
// import UnitInfoLine from "./UnitInfoLine";
// import ButtonCircle from "./ButtonCircle";


import ThemeData from "data/ThemeData";
// import NetData from "data/NetData";


// import xSmall from "img/x_white_small.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			height: 50,

			display: 'flex',
			boxSizing: 'border-box',
			alignItems: 'center',

			color: ThemeData.colours.secondaryFont,

			textAlign: 'center',

			borderBottom: `1px ${ThemeData.colours.white50} solid`,
		},
		rowBGColourOne: {
			backgroundColor: ThemeData.colours.rowColoursLight[0],
		},
		rowBGColourTwo: {
			backgroundColor: ThemeData.colours.rowColoursLight[1],
		},
		selectedItem: {
			backgroundColor: ThemeData.colours.cadetBlue,
		},
		floorplanImg: {
			flex: '0 0 auto',

			width: 105,
			height: 95,

			marginRight: 25,

			borderRadius: 4,

			backgroundColor: ThemeData.colours.white,

			'& > img': {
				width: 105,
				height: 95,

				objectFit: 'contain',
			},
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			color: ThemeData.colours.white75,

			textTransform: 'uppercase',
		},
		removeHomeButton: {
			flex: '0 0 auto',
			width: 48,
		},
		tableItem: {
			flex: '1 0 auto',
			width: 100,
		},
	},
	{
		name: "UnitDetailTableItem",
	}
)



const UnitDetailTableItem = ( { dataUnit, ...props } ) => {

	const classes = useStyles();

	// const apiUri = NetData.apiUri;

	return (
		<>
			<div
				className={
					`${classes.wrapper
					// } ${classes.selectedItem
					} ${props.itemIdx % 2 === 0 ?
						classes.rowBGColourOne :
						classes.rowBGColourTwo
					} ${dataUnit.custom_id === props.selectedUnitId ? classes.selectedItem : ''
					}`}
				onClick={() => {
					props.isToAllowSelection && props.setSelectedUnitId( dataUnit.custom_id );
					// props.setSelectedUnitData( dataUnit );
				}}
			>
				{/* <div className={classes.floorplanImg}>
				{data.floorplan_image && <img
					src={`${NetData.imgUri( data.floorplan_image.formats && data.floorplan_image.formats.small.url )}`}
					alt={data.floorplan_image.alternativeText}
				/>}
			</div> */}
				{/* <div className={classes.info}>
				<UnitInfoPlanNameTitle
					data={data}
					color={ThemeData.colours.primaryAccent}
					fontSize={20}
					className={classes.unitInfoPlanNameTitle}
				/>
				<UnitInfoLine
					data={data}
					className={classes.unitInfoLine}
				/>
			</div> */}
				{/* <div className={classes.removeHomeButton}>
				<ButtonCircle
					src={xSmall}
					alt="Remove This Saved Home"
					widthHeight={46}
					iconWidthHeight={26}
					borderColour={ThemeData.colours.white25}
					textColour={ThemeData.colours.white25}
					onClick={() => props.toggleSavedHome()}
				/>
			</div> */}
				{props.unitTableItemColumnsToDisplay.map( ( unitTableItemColumn, idx ) => (
					<div
						className={
							`${classes.tableItem
							}`}
						key={idx}
					>
						{unitTableItemColumn.displayedDataFormattingFunction(
							unitTableItemColumn.relationName ?
								dataUnit[unitTableItemColumn.relationName][unitTableItemColumn.fieldName] :
								dataUnit[unitTableItemColumn.fieldName]
						)
						}
					</div>
				) )}
			</div>
		</>
	);
}



UnitDetailTableItem.defaultProps = {
	isToAllowSelection: false,
}



export default UnitDetailTableItem;