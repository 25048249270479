import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";
import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";
import ButtonCircle from "./ButtonCircle";


import { ReactComponent as XSmall } from "img/x_currentColor_small.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			height: 150,

			display: 'flex',
			boxSizing: 'border-box',
			alignItems: 'center',

			padding: '20px 0 20px 0',

			textAlign: 'left',

			// borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
			borderBottom: `1px ${ThemeData.colours.cadetBlue} solid`,
		},
		floorplanImg: {
			flex: '0 0 auto',

			width: 105,
			height: 95,

			marginRight: 25,

			borderRadius: 4,

			backgroundColor: ThemeData.colours.white,

			'& > img': {
				width: 105,
				height: 95,

				objectFit: 'contain',
			},
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			maxWidth: 200,

			color: ThemeData.colours.white75,

			textTransform: 'uppercase',
		},
		removeHomeButton: {
			flex: '0 0 auto',

			width: 48,

			color: ThemeData.colours.primaryAccent,
		},
	},
	{
		name: "UnitDetailListItemMini",
	}
)



const UnitDetailListItemMini = ( { data, ...props } ) => {

	const classes = useStyles();

	// const apiUri = NetData.apiUri;

	return (
		<div className={classes.wrapper}>
			<div className={classes.floorplanImg}>
				{data.floorplan_image && <img
					src={`${data.floorplan_image.formats && data.floorplan_image.formats.thumbnail.url }`}
					alt={data.floorplan_image.alternativeText}
				/>}
			</div>
			<div className={classes.info}>
				<UnitInfoPlanNameTitle
					data={data}
					color={ThemeData.colours.pearlWhite}
					fontSize={20}
					className={classes.unitInfoPlanNameTitle}
				/>
				<UnitInfoLine
					data={data}
					className={classes.unitInfoLine}
				/>
			</div>
			<div className={classes.removeHomeButton}>
				<ButtonCircle
					alt="Remove This Saved Home"
					widthHeight={46}
					iconWidthHeight={26}
					borderColour={ThemeData.colours.white25}
					textColour={ThemeData.colours.cadetBlue}
					hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
					onClick={() => props.toggleSavedHome()}
				>
					<XSmall />
				</ButtonCircle>
			</div>
		</div>
	);
}



export default UnitDetailListItemMini;