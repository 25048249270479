const ConstantData = {
	contentCardForms: {
		oneUp: "oneUp",
		twoUpHoriz: "twoUpHoriz",
		twoUpVert: "twoUpVert",
		fourUp: "fourUp",
		sixUp: "sixUp",
	},
	contentPageCustomFunctions: {
		interactiveMap: "interactiveMap",
		photoGallery: "photoGallery",
		floorplans: "floorplans",
		photosOnly: "photosOnly",
		virtualTour: "virtualTour",
	},
	// dataFormattingFunctions: {
	// 	prices: value => `$${value.toLocaleString()}`,
	// },
	imgSizes: {
		raw: 'raw',
		xlarge: 'xlarge',
		large: 'large',
		medium: 'medium',
		small: 'small',
		xsmall: 'xsmall',
	},
	localStorageKeys: {
		savedHomes: "savedHomes",
		siteFeatureToggles: "siteFeatureToggles",
		registration: "registration",
		unitsFilter: "unitsFilter",
		unitsFilterResult: "unitsFilterResult",
	},
	galleryPhotoDefaultCategories: {
		all: "all",
	},
	gqlQueryToSubqueryTypesMap: {
		unitFloors: "unit_floor",
		unitBuildings: "unit_building",
		unitHomeTypes: "unit_home_type",
		unitPlanTypes: "unit_plan_type",
		unitExposures: "unit_exposures",
	},
	scrollIconDir: {
		vertical: "vertical",
		horizontal: "horizontal",
	},
	sectionCustomFunctions: {
		proxima: "proxima",
		floorplans: "floorplans",
		gallery: "gallery",
		map: "map",
	},
	TopMenu: {
		defaultSectionContentToDisplay: "default",
	},

	unitFilterViewModes: {
		card: "card",
		list: "list",
		compare: "compare",
	},
	unitFilterTypes: {
		checkedList: "checkedList",
		range: "range",
	},
	webglViewTypes: {
		byFloorplateDetailed: "byFloorplateDetailed",
		byFloorplateSimple: "byFloorplateSimple",
		building3DModel: "building3DModel",
	},
	webglWindowMessageTypes: {
		webglHotspotClicked: "webglHotspotClicked",
	},

};

export default ConstantData;