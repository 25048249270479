import React, {

} from 'react';
import {
	Link
} from 'react-router-dom';

// import {
// 	motion
// } from 'framer-motion';

import {
	createUseStyles
} from 'react-jss';

import ThemeData from '../data/ThemeData';
import HeaderContextData from '../data/HeaderContextData';

// import ashEdwardLogo from "../img/ash_edward_logo_light.svg";
// import devLogo from "img/kindred_logo_darkFill.png"
// import devLogo from "img/kindred_logo_darkFill_small.png"
// import devLogo from "img/neu-logo-white-small-space.png"
// import devLogo from "img/v_on_e49_colour.svg"
import devLogo from "img/v_on_e49_colour_with_white_text.svg"
// import devLogo from "img/v_on_e49_black.svg"
// import devLogo from "img/v_on_e49_white.svg"
// import useSavedHomes from 'hooks/useSavedHomes';
import SavedHomesTitle from './SavedHomesTitle';

const useStyles = createUseStyles(
	{
		header: {
			// flex: '0 0 auto',
			display: 'flex',
			justifyContent: 'space-between',
			boxSizing: 'border-box',
			height: 'var( --header-height )',
			fontWeight: 'bold',

			borderBottom: ThemeData.colours.paneBorders,
		},
		headerSection: {
			flex: '1 0 auto',

			height: 100,
			width: 350,

			// border: '1px #00FFFF solid',
		},
		headerLeft: {
			display: 'flex',
			alignItems: 'center',
		},
		headerMiddle: {
			// margin: '0 auto',
			marginTop: '10px',

			maxHeight: '80%',
			background: 'no-repeat center',
			backgroundImage: `url( ${devLogo} )`,
			backgroundSize: 'contain',

			cursor: 'pointer',
		},
		headerRight: {
			display: 'flex',
			alignItems: '',
			justifyContent: 'flex-end',

			...ThemeData.typography.nameplate,
		},
		headerRightItem: {
			height: 'calc( var( --header-height ) - 1px )',

			lineHeight: 'var( --header-height )',

			'& > p': {
				margin: 0,
			},
		},
		savedHomes: {
			flex: '0 0 auto',

			width: 175,

			marginRight: 50,

			cursor: 'pointer',
		},
		// savedHomesNumberText: {
		// 	display: 'inline',

		// 	margin: '0 5px',

		// 	backgroundColor: ThemeData.colours.primaryBg,

		// 	borderRadius: 10,

		// 	'.mainMenuHeader &': {
		// 		backgroundColor: ThemeData.colours.primaryAccent,
		// 	},
		// },
		register: {
			flex: '0 0 auto',

			width: 175,
			lineHeight: '100px',

			...ThemeData.ux.fillToRight,

			borderLeft: `1px ${ThemeData.colours.white50} solid`,

			cursor: 'pointer',

			'& p': {
				margin: 0,
			}
		},
		// prevMainMenuButton: {
		// 	backgroundColor: 'blue',
		// 	'.isOpen &': {
		// 		backgroundColor: 'red',
		// 	},
		// 	'&:hover': {
		// 		backgroundColor: 'purple',
		// 	},
		// },
	},
	{ name: 'HeaderComponents' }
);


function HeaderComponents( { children, headerContext, ...props } ) {

	const classes = useStyles();

	return (
		<>
			<div className={classes.header}>
				<div className={`${classes.headerSection} ${classes.headerLeft}`} >
					{children}
				</div>
				<Link
					to="/"
					className={`${classes.headerSection} ${classes.headerMiddle}`}>
				</Link>
				<div className={`${classes.headerSection} ${classes.headerRight} ${headerContext && headerContext === HeaderContextData.mainMenuHeaderContext ? 'mainMenuHeader' : ''}`}>
					{headerContext !== HeaderContextData.contentPageHeaderContext &&
						<SavedHomesTitle
							className={`${classes.savedHomes} ${classes.headerRightItem}`}
							onClick={() => props.toggleIsSavedHomesSlideoutOpen()}
						/>
					}
					{/* {headerContext !== HeaderContextData.contentPageHeaderContext &&
						<div
							className={`${classes.register} ${classes.headerRightItem}`}
							onClick={() => props.toggleIsRegisterOpen()}>
							<p>Register</p>
						</div>
					} */}
				</div>
			</div>
		</>
	)

}


export default HeaderComponents;