import React, {
	useState,
	useEffect,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { useLocation } from "react-router-dom";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";




import ThemeData from "data/ThemeData";



const useStyles = createUseStyles(
	{
		wrapper: {
			padding: '25px 40px 17px 40px',

			textAlign: 'left',
		},
		list: {
			color: ThemeData.colours.secondaryFont,
			backgroundColor: ThemeData.colours.secondaryBg,

			// border: `1px ${ThemeData.colours.black} solid`,
		},
	},
	{
		name: "InputRangeSlider",
	}
)


let debounceFilterChangeTimerId;

// data 					=== any data with an id field
// props.listLabelField 	=== name of the field to use as the listItem's label

const InputRangeSlider = ( { data, className, ...props } ) => {
	const location = useLocation();
	const classes = useStyles();

	const [checked, setChecked] = useState( false );


	let currLowVal = props.min;
	let currHighVal = props.max;
	if ( props.filterSettings && props.filterSettings[props.filterType] ) {
		currLowVal = props.filterSettings[props.filterType].low;
		currHighVal = props.filterSettings[props.filterType].high;
	}

	const [values, setValues] = useState( [currLowVal, currHighVal] );
	const handleValueChange = ( e, newValues ) => {
		setValues( newValues );
	};



	const debounceFilterChangeFunc = ( funcToDebounce, debounceDelay ) => {
		clearTimeout( debounceFilterChangeTimerId );

		debounceFilterChangeTimerId = setTimeout( funcToDebounce, debounceDelay );
	};


	// const rangeMarkFormattingFunction = ( value ) => {
	// 	return `${value / 1000}K`
	// };

	const rangeMarks = [
		{
			value: props.min,
			label: props.displayedDataFormattingFunction( props.min ),
		},
		{
			value: props.max,
			label: props.displayedDataFormattingFunction( props.max ),
		},
	];




	const setFilterAfterValueChange = ( newValues ) => {
		// console.log( "Values Debounce", newValues );

		const newFilterSettings = { ...props.filterSettings };

		// if ( !newFilterSettings.hasOwnProperty( props.filterType ) )

		if ( newValues[0] === props.min && newValues[1] === props.max ) {
			if ( newFilterSettings.hasOwnProperty( props.filterType ) ) {
				delete ( newFilterSettings[props.filterType] );
			}
		} else {
			// if ( props.filterSettings && !props.filterSettings.hasOwnProperty( props.filterType ) )
			// 	newFilterSettings[props.filterType] = {};

			// if ( newValues[0] !== newFilterSettings[props.filterType].low || newValues[1] !== newFilterSettings[props.filterType].high ) {

				newFilterSettings[props.filterType] = {
					low: newValues[0],
					high: newValues[1],
				};
				sendGoogleAnalyticsEvent(
					`Filter Button Click, ${location.pathname}`,
					`User Clicks Filter - ${props.filterType}, ${newValues[0]}-${newValues[1]}`,
					`Filter - ${props.filterType}, ${newValues[0]}-${newValues[1]}`,
					`${props.filterType}, ${newValues[0]}-${newValues[1]}`
				);	

				// props.setFilterSettings( newFilterSettings );

			// }
		}

		// if ( !newFilterSettings[props.filterType].hasOwnProperty( 'low' ) )
		// 	newFilterSettings[props.filterType] = {
		// 		// [listItemId]: {
		// 		doFilterByOr: true,
		// 		// },
		// 	};
		// console.log( 'nfs', newFilterSettings );
		props.setFilterSettings( newFilterSettings );
	}


	useEffect( () => {
		// console.log( "Values Prebounce", values )
		debounceFilterChangeFunc( () => setFilterAfterValueChange( values ), 300 );
	}, [values] );


	const handleToggle = ( listItemId ) => () => {
		const currentIndex = checked.indexOf( listItemId );
		const newChecked = [...checked];

		if ( currentIndex === -1 ) {
			newChecked.push( listItemId );
		} else {
			newChecked.splice( currentIndex, 1 );
		}

		setChecked( newChecked );


		// const thisListItemIdx = data.indexOf( listItemId );
		// console.log( 'filterType', props.filterType, 'cListdata', data );
		// console.log( data, data[listItemId] );

		// if ( currentIdx === -1 ) {
		// 	// this is an error
		// 	// TODO: Figure out safeguarded implementation for checking if this path ever happens
		// } else {
		// 	// if ( !data[currentIdx].hasOwnProperty( props.listItemCheckedField ) )
		// 	// 	data[currentIdx][props.listItemCheckedField] = true;
		// 	// else
		// 		data[currentIdx][props.listItemCheckedField] = !data[currentIdx][props.listItemCheckedField];
		// }
		const newFilterSettings = { ...props.filterSettings };
		// if ( thisListItemIdx === -1 ) {
		// 	// this is an error
		// 	// TODO: Figure out safeguarded implementation for checking if this path ever happens
		// } else {
		if ( !newFilterSettings.hasOwnProperty( props.filterType ) )
			newFilterSettings[props.filterType] = {};
		if ( !newFilterSettings[props.filterType].hasOwnProperty( listItemId ) )
			newFilterSettings[props.filterType][listItemId] = {
				// [listItemId]: {
				doFilterByOr: true,
				// },
			};
		// if ( !newFilterSettings[currentIdx].hasOwnProperty( props.listItemCheckedField ) )
		// 	data[currentIdx][props.listItemCheckedField] = true;
		// else
		// 	data[thisListItemIdx][props.listItemCheckedField] = !data[thisListItemIdx][props.listItemCheckedField];
		else
			newFilterSettings[props.filterType][listItemId].doFilterByOr = !newFilterSettings[props.filterType][listItemId].doFilterByOr;

		props.setFilterSettings( newFilterSettings );
		// }
	};

	// console.log( "CheckedList", data );

	// TODO: Figure out how to colour the elements of the slider with greater fidelity

	return (
		<div className={`${classes.wrapper} ${className}`}>
			{/* {data && data.unit_number} */}
			<Box sx={{ width: 300 }}>
				<Slider
					sx={{
						color: ThemeData.colours.secondaryFont,
						'&.MuiSlider-thumb': {
							color: ThemeData.colours.primaryAccent,
						},
						'&.MuiSlider-valueLabelOpen': {
							color: ThemeData.colours.primaryAccent,
						},
					}}
					getAriaLabel={() => props.label}
					// defaultValue={50000}
					value={values}
					onChange={handleValueChange}
					getAriaValueText={props.displayedDataFormattingFunction}
					step={props.step}
					marks={rangeMarks}
					min={props.min}
					max={props.max}
					valueLabelDisplay='auto'
					valueLabelFormat={props.displayedDataFormattingFunction}
				/>
			</Box>
			{/* <List
				className={classes.list}
				sx={{
					width: '100%',
					maxWidth: 360,
					// bgcolor: ThemeData.colours.primaryBg
				}}
			>
				{data.map( ( listItemData ) => {
					const labelId = `checkbox-list-label-${listItemData.id}`;

					return (

						<ListItem
							key={listItemData.id}
							// className={classes.list}
							// secondaryAction={
							// 	<IconButton edge="end" aria-label="comments">
							// 		<CommentIcon />
							// 	</IconButton>
							// }
							disablePadding
						>
							<ListItemButton role={undefined} onClick={handleToggle( listItemData.id )} dense>
								<ListItemIcon>
									<Checkbox
										sx={{
											color: ThemeData.colours.secondaryFont,
											'&.Mui-checked': {
												color: ThemeData.colours.secondaryFont,
											},
										}}
										edge="start"
										// checked={checked.indexOf( listItemData.id ) !== -1}
										checked={
											( props.filterSettings &&
											props.filterSettings[props.filterType] &&
											props.filterSettings[props.filterType][listItemData.id] ) !== undefined && 
											props.filterSettings[props.filterType][listItemData.id].doFilterByOr}
										tabIndex={-1}
										disableRipple
										inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={`${listItemData[props.listLabelField]}`}
									primaryTypographyProps={{
										fontFamily: 'inherit',
									}}
								/>
							</ListItemButton>
						</ListItem>
					);
				} )}
			</List> */}
		</div>
	);
}



export default InputRangeSlider;