import React, {
	useState,
	useMemo,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";
import ConstantData from "data/ConstantData";


import richifyTextBasic from "util/richifyTextBasic";


import ClearConfirmationDialogGeneric from "./ClearConfirmationDialogGeneric";
import Gallery from "./Gallery";
import MainMenuIconItems from "./MainMenuIconItems";
import TopMenuContentPage from "./TopMenuContentPage";


import useRegistration from "hooks/useRegistration";
import useSavedHomes from "hooks/useSavedHomes";
import useOpenClosePane from "hooks/useOpenClosePane";



const useStyles = createUseStyles(
	{
		wrapper: {
			textAlign: 'left',
		},
		mainMenuTwoColumn: {
			display: 'flex',
			height: 'calc( 100vh - 100px )',
			// VonE49 colour
			backgroundColor: ThemeData.colours.prussianBlue,			
		},
		column: {
			width: '50%',

			boxSizing: 'border-box',

			overflowY: 'auto',
			...ThemeData.styles.thinScrollbarLightOnDark,
		},
		leftColumn: {
			padding: '40px 0 0 100px',

			'& hr': {
				margin: '20px 100px 20px 0',
			},
		},
		rightColumn: {
			borderLeft: ThemeData.colours.paneBorders,
		},
		columnContent: {
			position: 'relative',

			textAlign: 'left',
		},
		leftColumnBottomContent: {
		},
		rightColumnContent: {
			padding: '60px 100px 0 100px',
		},
		mainMenuNav: {

			fontFamily: ThemeData.fonts.copy,
			fontSize: '3em',

			'& ul': {
				margin: 0,
				padding: 0,

				flex: '1 1 auto',

				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
			},
		},
		navListItem: {
			position: 'relative',

			padding: '20px 0',

			flex: '0 1 auto',

			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',

			listStyleType: 'none',
			letterSpacing: 0,
		},
		navListItemCount: {
			position: 'absolute',

			left: -50,

			fontSize: 15.2,
		},
		overlayContentPage: {
			boxSizing: 'border-box',
			width: '100%',
			height: '100%',

			// backgroundColor: ThemeData.colours.primaryBg,
			// VonE49 colour
			backgroundColor: ThemeData.colours.prussianBlue,

			zIndex: 4,
		},
		labelTextColumn: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		itemLabelText: {
			flex: '1 0 auto',
			display: 'flex',
			justifyContent: 'start',

			marginBottom: 40,

			fontSize: '15.2px',
			textAlign: 'left',
			lineHeight: '23px',
		},
		itemLabel: {
			flex: '1 0 auto',
			display: 'inline-block',
			width: 125,

			fontFamily: ThemeData.fonts.title,
			textTransform: 'uppercase',
			letterSpacing: 0,
		},
		itemText: {
			display: 'inline-block',

			fontFamily: ThemeData.fonts.copy,
			letterSpacing: '.05em',
			whiteSpace: 'pre-wrap',
		},
		stickmap: {
			marginTop: 10,

			maxWidth: '100%',
			maxHeight: '400px',
		},
		contactSocial: {
			display: 'inline-block',

			marginRight: 50,

			...ThemeData.styles.linkColourRemoval,
		},
		partnerImagesWrapper: {
			display: 'flex',
			alignItems: 'center',
		},
		partnerImageWrapper: {
			flex: '1 0 auto',

			marginRight: 40,
		},
		partnerImage: {
			height: 40,
			maxWidth: 200,

			margin: 0,
		},
		disclaimer: {
			marginBottom: 30,

			fontFamily: ThemeData.fonts.copy,
			fontSize: 10,
			letterSpacing: 0.1,
			lineHeight: 1.5,

			'& > p': {
				margin: 0,
			},
		},
		resetPoweredGroup: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',

			fontSize: 9,
			fontFamily: ThemeData.fonts.copy,
		},
		resetSiteButton: {
			width: 200,
			cursor: 'pointer',
		},
		poweredByProximaWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',

			width: '100%',
		},
		poweredByProximaText: {
			width: 200,

			fontSize: '9.5px',
			textAlign: 'right',
			marginRight: '35px',
			letterSpacing: '.335em',
		},
		poweredByProximaImg: {

		},
	},
	{
		name: "TopMenu",
	}
)



const TopMenu = ( { dataNav, className, ...props } ) => {

	const classes = useStyles();


	// console.log( "navData", dataNav );


	const registration = useRegistration();
	const savedHomes = useSavedHomes();


	const {
		isClearConfirmationDialogOpen,
		setIsClearConfirmationDialogOpenToOpen,
		setIsClearConfirmationDialogOpenToClosed,
	} = useOpenClosePane( "ClearConfirmationDialog" );


	const sectionContent = useMemo( () => {
		let contentObj = {
			[ConstantData.TopMenu.defaultSectionContentToDisplay]: (
				<></>
			)
		}

		for ( let navItemIdx in dataNav.navItems ) {
			let navItem = dataNav.navItems[navItemIdx];
			if ( navItem.section.custom_override_function === ConstantData.sectionCustomFunctions.gallery ) {
				contentObj[navItem.section.slug] = (
					<motion.div
						className={`${classes.rightColumnContent} ${classes.overlayContentPage}`}
					>
						<Gallery
							doOverrideUseFullPhotoGallery
							noPadding
						/>
					</motion.div>
				);
			} else {
				contentObj[navItem.section.slug] = (
					<motion.div
						className={`${classes.rightColumnContent} ${classes.overlayContentPage}`}
					>
						{[...navItem.section.content_pages]
							.filter( cp => !cp.is_only_image_card )
							.sort( ( cpA, cpB ) => cpA.order - cpB.order )
							.map( ( contentPage, idx, cps ) => (
								<div
									key={idx}
								>
									<div className={classes.contentPageCounter}>
										{idx + 1} / {cps.length}
									</div>
									<TopMenuContentPage
										dataContentPage={contentPage}
									/>
								</div>
							) )
						}
					</motion.div>
				);
			}
		}

		return contentObj;
	}, [dataNav, classes] );

	const [sectionContentToDisplay, setSectionContentToDisplay] = useState( ConstantData.TopMenu.defaultSectionContentToDisplay );

	const hoveringSectionContentDisplay = ( sectionSlug, hoveringRightPane ) => {
		// console.log( "ss", sectionSlug, "hrp", hoveringRightPane );
	};


	// console.log( "tmSect", sectionContent );


	return (
		<div className={`${classes.wrapper} ${className}`}>
			<div className={classes.mainMenuTwoColumn}>
				<div
					className={`${classes.column} ${classes.leftColumn}`}
					onClick={() => setSectionContentToDisplay( ConstantData.TopMenu.defaultSectionContentToDisplay )}
					onMouseEnter={() => setSectionContentToDisplay( ConstantData.TopMenu.defaultSectionContentToDisplay )}
					// onMouseOver={() => setSectionContentToDisplay( ConstantData.TopMenu.defaultSectionContentToDisplay )}
					onScroll={( e ) => e.stopPropagation()}
				>
					<div className={classes.columnContent} >
						<div className={classes.mainMenuNav}>
							<ul>
								{[...dataNav.navItems]
									.sort( ( itemA, itemB ) => itemA.order - itemB.order )
									.map( ( navItem, idx ) => {
										return (
											<li
												className={classes.navListItem}
												key={navItem.id}
												// onHoverStart={() => setSectionContentToDisplay( navItem.section.slug )}
												onClick={( e ) => {
													setSectionContentToDisplay( navItem.section.slug );
													e.stopPropagation();
												}}
												onMouseEnter={() => setSectionContentToDisplay( navItem.section.slug )}
											// onMouseEnter={() => hoveringSectionContentDisplay( navItem.section.slug, false )}
											// onMouseOut={() => setSectionContentToDisplay( ConstantData.TopMenu.defaultSectionContentToDisplay )}
											>
												{navItem.alt_name ? navItem.alt_name : navItem.section.name}
												{sectionContentToDisplay === navItem.section.slug &&
													<div className={classes.navListItemCount}>
														0{idx + 1} /
													</div>
												}
											</li>
										)
									} )}
							</ul>
						</div>
						{/* <hr /> */}
						<div
							className={classes.leftColumnBottomContent}
							onClick={() => setSectionContentToDisplay( ConstantData.TopMenu.defaultSectionContentToDisplay )}
							onMouseEnter={() => setSectionContentToDisplay( ConstantData.TopMenu.defaultSectionContentToDisplay )}
						>
							<MainMenuIconItems />
						</div>
					</div>
				</div>
				<div className={`${classes.column} ${classes.rightColumn}`}
					onMouseEnter={() => hoveringSectionContentDisplay( null, true )}
				>
					<div className={`${classes.columnContent}`}>
						{/* {sectionContent && sectionContent[sectionContentToDisplay]} */}
						{sectionContent[sectionContentToDisplay]}
						<div
							className={`${classes.rightColumnContent} ${classes.labelTextColumn}`}
							hidden={sectionContentToDisplay !== ConstantData.TopMenu.defaultSectionContentToDisplay}
						>
							<div className={classes.itemLabelText}>
								<div className={classes.itemLabel}>
									Contact
								</div>
								<div className={classes.itemText}>
									{dataNav.contactInfo.email}<br />
									{dataNav.contactInfo.tel}
								</div>
							</div>
							<div className={classes.itemLabelText}>
								<div className={classes.itemLabel}>
									Visit Us
								</div>
								<div className={classes.itemText}>
									{dataNav.contactInfo.address}<br />
									<img className={classes.stickmap} src={`${NetData.imgUri( dataNav.contactInfo.map_image.url )}`} alt={dataNav.contactInfo.map_image.alternativeText} />
									{
										// dataNav.contactInfo.address.replace( /(?:\r\n|\r|\n)/g, <br /> )} 
									}

									{
										// && console.log( dataNav.contactInfo.address )
									}
								</div>
							</div>
							{dataNav && dataNav.contactSocials && dataNav.contactSocials.length > 0 &&
								<div className={classes.itemLabelText}>
									<div className={classes.itemLabel}>
										Socials
									</div>
									<div className={classes.itemText}>
										{dataNav.contactSocials.map( ( contactSocial ) => {
											return (
												<div className={classes.contactSocial} key={contactSocial.id}>
													<a href={contactSocial.link}>
														{contactSocial.network_name}
													</a>
												</div>
											);
										} )}
									</div>
								</div>}
							{( dataNav.contactInfo.partner_images && dataNav.contactInfo.partner_images.length > 0 ) &&
								<div className={classes.itemLabelText}>
									<div className={classes.itemLabel}>
										Our Team
									</div>
									<div className={classes.itemText}>
										<div className={classes.partnerImagesWrapper}>
											{dataNav.contactInfo.partner_images.map( ( partnerImage ) => {
												return (
													<div className={classes.partnerImageWrapper} key={partnerImage.id}>
														<img
															className={classes.partnerImage}
															src={`${NetData.imgUri( partnerImage.url )}`}
															alt={partnerImage.alternativeText}
														// width={partnerImage.width}
														// height={partnerImage.height}
														/>
													</div>
												);
											} )}
										</div>
									</div>
								</div>
							}
							{dataNav.contactInfo.disclaimer &&
								<div className={classes.disclaimer}>
									<p dangerouslySetInnerHTML={{ __html: richifyTextBasic( dataNav.contactInfo.disclaimer ) }} />
								</div>}
							<div className={classes.resetPoweredGroup}>
								<div
									className={classes.resetSiteButton}
									onClick={() => setIsClearConfirmationDialogOpenToOpen()}
								>
									Clear Site Data
								</div>
								<ClearConfirmationDialogGeneric
									dataTypeToClearName={"Site Data"}
									isClearConfirmationDialogOpen={isClearConfirmationDialogOpen}
									setIsClearConfirmationDialogOpenToClosed={setIsClearConfirmationDialogOpenToClosed}
									afterClearConfirmationPerformFunction={() => {
										registration.clearRegistration();
										savedHomes.clearSavedHomes();
									}}
								/>
								<div className={classes.poweredByProximaWrapper}>
									<div className={`${classes.itemLabel} ${classes.poweredByProximaText}`}>
										{dataNav.contactInfo.tagline}
									</div>
									<div className={classes.poweredByProximaImg}>
										<img src={`${NetData.imgUri( dataNav.contactInfo.powered_by_image.url )}`} alt={dataNav.contactInfo.powered_by_image.alternativeText} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}



export default TopMenu;