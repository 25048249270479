import React from "react";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";

import {
	HiHeart,
	HiOutlineHeart,
} from "react-icons/hi";


import ThemeData from "../data/ThemeData";
import QueryData from "../data/QueryData";
import NetData from "../data/NetData";
import ConstantData from "data/ConstantData";
// import SiteConfigData from "data/SiteConfigData";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import ButtonCircle from "./ButtonCircle";
import HeaderSimple from "./HeaderSimple";
// import FloorplanSelectionSpinner from "./FloorplanSelectionSpinner";
import UnitInfoIconList from "./UnitInfoIconList";
import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
import VirtualTour from "./VirtualTour";


import useSavedHomes from "hooks/useSavedHomes";
import useOpenClosePane from "hooks/useOpenClosePane";
import useSiteConfiguration from "hooks/useSiteConfiguration";


import imgFunctions from "util/imgFunctions";

import  { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";


// import navArrowLeft from "../img/nav_arrow_left.svg";
import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import keyplanImg from "../img/floorplate_location_example.png";
// import northArrowIcon from "../img/icon_north_arrow.svg";
// import plusIcon from "../img/icon_plus.svg";
import { ReactComponent as DownloadIcon } from "../img/icon_download.svg";
// import { ReactComponent as PlaneIcon } from "../img/icon_paper_plane.svg";
import SlideUpGenericHolder from "./SlideUpGenericHolder";
import ContentPage from "./ContentPage";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',
			// height: 'calc( 100vh - 100px )',

			color: ThemeData.colours.secondaryFont,
			backgroundColor: ThemeData.colours.primaryBg,


		},
		header: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: 100,

			backgroundColor: ThemeData.colours.blackBg5,
		},
		backButton: {
			width: 100,
			height: 100,

			cursor: 'pointer',

			color: ThemeData.colours.apricotOrange,

			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
		twoColumn: {
			display: 'flex',
			justifyContent: 'center',

			height: '100%',
		},
		column: {
			flex: '1 0 auto',

			boxSizing: 'border-box',
			width: 'calc( 50% - 200px )',

			padding: '50px 0',
		},
		leftColumn: {
			width: '63.33%',
			paddingTop: 0,

			backgroundColor: ThemeData.colours.white,
		},
		rightColumn: {
			width: '36.77%',
			paddingInline: 40,

			backgroundColor: ThemeData.colours.pearlWhite,
		},
		floorPlan: {

		},
		floorPlanImg: {
			// height: '100%',
			width: '100%',

    		marginTop: '5%',

			objectFit: 'contain',
		},
		floorPlanButtons: {
			margin: '0 auto 0 auto',

			maxWidth: 350,
		},
		keyplan: {
			paddingTop: 50,

			display: 'flex',
			justifyContent: 'center',
		},
		nArrow: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',

			marginRight: 50,
		},
		nArrowArrow: {
			flex: '0 0 auto',

			width: 20,
			height: 33,
		},
		nArrowN: {
			flex: '0 0 auto',

			color: ThemeData.colours.primaryBg,
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 23,
		},
		floorplanName: {
			color: ThemeData.colours.prussianBlue,

			textAlign: 'left',
			fontFamily: ThemeData.fonts.title,
			fontSize: 40,
		},
		hr: {
			width: '100%',

			marginTop: 35,

			borderTop: `1px ${ThemeData.colours.lighterBorderDarkOnLight} solid`,
		},
		actionButtons: {
			// width: '100%',
			width: 150,

			marginTop: 35,
			marginInline: 'auto',
		},
		detailListItems: {
			margin: 0,
			marginTop: 50,
			padding: 0,

			display: 'flex',
			justifyContent: 'left',

			listStyleType: 'none',

			textAlign: 'left',
			letterSpacing: '.05em',
		},
		detailList: {
		},
		detailListItem: {
			width: 150,

			flex: '0 0 auto',
		},
		detailListItemIcon: {

		},
		detailListItemInline: {
			display: 'inline-block',
			marginRight: 10,
		},
		detailListItemTitle: {
			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		detailListItemData: {
			marginTop: 10,

			letterSpacing: '.05em',
			color: ThemeData.colours.primaryBg,
			fontSize: 16,
		},
		detailListItemDataModifier: {
			fontFamily: ThemeData.fonts.copy,
		},
		unitDescription: {
			marginTop: 50,

			color: ThemeData.colours.grey,

			textAlign: 'left',
			lineHeight: '1.9em',
			fontFamily: ThemeData.fonts.copy,
			fontSize: 16,
			letterSpacing: '.025em',
		},
		furtherUnitDetailsButtons: {
			marginTop: 50,
		},
		keyplanImg: {
			flex: '0 0 auto',

			minWidth: 160,
			maxWidth: '33%',

			objectFit: 'contain',
		},
		disclaimer: {
			display: 'flex',
			fontSize: '16px', 
			position: 'absolute',
			bottom: '5%',
    		left: '5%',
			width: '50%',
			letterSpacing: 0,

			fontFamily: ThemeData.fonts.proximaNova,
		}
	},
	{
		name: "UnitDetail",
	}
)



const wrapperAnimVariants = {
	open: {
		y: 0,
		zIndex: 6,
		display: 'block',
	},
	closed: {
		y: "100vh",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}



function RenderUnitDetail( unitId, unit, classes, error, isOpen, toggleUnitDetailFcn, hooks ) {

	// console.log( 'UnitDetail isOpen', isOpen );

	const apiUri = NetData.apiUri;

	return (
		<>
			<motion.div
				className={classes.wrapper}
				initial={false}
				animate={isOpen ? "open" : "closed"}
				variants={wrapperAnimVariants}
				transition={{
					type: 'linear',
					duration: .5,
				}}
			>
				<HeaderSimple
					backgroundColor={ThemeData.colours.prussianBlue}
					onBackClick={toggleUnitDetailFcn} />
				<div className={classes.twoColumn}>
					<div className={`${classes.column} ${classes.leftColumn}`}>
						<div className={classes.floorPlan}>
							{unit && unit.unit_plan_type && unit.unit_plan_type.floorplan_image &&
								<>
									<img
										className={classes.floorPlanImg}
										// src={imgFunctions.GetImgBySizeUri( unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
										// // src={NetData.imgUri( unit && unit.unit_plan_type.floorplan_image.url )}
										// alt={unit.unit_plan_type.floorplan_image.alternativeText}
										// width={imgFunctions.GetImgBySizeWidth(unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
										// height={imgFunctions.GetImgBySizeHeight(unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.raw )}
										{...imgFunctions.GetAllImgElmProps( unit.unit_plan_type.floorplan_image, ConstantData.imgSizes.medium )}
									/>
									<div className={classes.disclaimer}>
									{`The developer reserves the right to make modifications to floor plans, features and/or finishes without notice. 
									Sizes are based on architectural measurements. E.&O.E.`}
									</div>
								</>
							}
							{/* {( !unit || !unit.unit_plan_type || !unit.unit_plan_type.floorplan_image ) &&
								<img
									className={classes.floorPlanImg}
									src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
									alt={'floorplan placeholder large'} />
							} */}
						</div>
						<div className={classes.floorPlanButtons}>
							<ButtonGroup>
								{unit && unit.view_study_iframe_url &&
									<ButtonOutline
										label="Enjoy the View"
										padding={20}
										textColour={ThemeData.colours.primaryAccent}
										hoverTextColour={ThemeData.colours.white}
										borderColour={ThemeData.colours.lighterBorderDarkOnLight}
										to="."
										firstInColl

										onClick={() => hooks.toggleIsViewStudySlideUpOpen()}
									/>
								}
								{/* <ButtonOutline
									label="Enter Showhome"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.lighterBorderDarkOnLight}
									to="."
									firstInColl

									onClick={() => hooks.toggleIsVirtualTourOpen()}
								/> */}
							</ButtonGroup>
						</div>
					</div>
					<div className={`${classes.column} ${classes.rightColumn}`}>
						<UnitInfoPlanNameTitle
							data={unit}
							fontSize={36}
							doShowUnitNumber={true}
							className={classes.floorplanName}
						/>
						{/* <FloorplanSelectionSpinner
						selectedFloorplanId={unit && unit.unit_plan_type && unit.unit_plan_type.id}
						floorplanName={unit && unit.unit_plan_type && unit.unit_plan_type.name}
						floorplanHomeType={unit && unit.unit_home_type && unit.unit_home_type.name}
					/> */}
						<div className={classes.actionButtons}>
							<ButtonGroup
							>
								{/* <ButtonOutline
									label="Book An Appointment"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.lighterBorderDarkOnLight}
									to="."
									firstInColl
								></ButtonOutline> */}
								<ButtonOutline
									fontSize={25}
									textColour={ThemeData.colours.tealBlue}
									backgroundColour={ThemeData.colours.pearlWhite}
									hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
									hoverBackgroundColour={ThemeData.colours.primaryFontHighestConstrast}
									borderColour={ThemeData.colours.tealBlue}
									isCircle={true}
									firstInColl
									onClick={() => {
										hooks.toggleSavedHome( unit && unit.id );
										sendGoogleAnalyticsEvent(
										'User Saves Floor Plan',
										`User Saves ${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
										`${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
										`${unit.unit_plan_type.name} Unit ${unit.unit_number}`
									)}}
									isActive={hooks.savedHomesIncludes( unit && unit.id )}
								>
									{hooks.savedHomesIncludes( unit && unit.id ) ?
										<HiHeart /> :
										<HiOutlineHeart />
									}
								</ButtonOutline>
								<a
									href={unit && unit.floorplan_pdf && unit.floorplan_pdf.url}
									download
								>
									<ButtonCircle
										alt="Download PDF"
										textColour={ThemeData.colours.tealBlue}
										backgroundColour={ThemeData.colours.pearlWhite}
										hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
										hoverBackgroundColour={ThemeData.colours.primaryFontHighestConstrast}
										borderColour={ThemeData.colours.tealBlue}
										onClick={() => sendGoogleAnalyticsEvent(
											'User Saves Floor Plan as PDF',
											`User Saves ${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
                        					`${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
                        					`${unit.unit_plan_type.name} Unit ${unit.unit_number}`
										)}

									>
										<DownloadIcon />
									</ButtonCircle>
								</a>
								{/* <ButtonCircle
									alt="Send Floorplan"
									textColour={ThemeData.colours.primaryAccent}
									backgroundColour={ThemeData.colours.secondaryBgHighContrast}
									hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
									hoverBackgroundColour={ThemeData.colours.primaryFontHighestConstrast}
									borderColour={ThemeData.colours.lighterBorderDarkOnLight}
								>
									<PlaneIcon />
								</ButtonCircle> */}
							</ButtonGroup>
						</div>
						<div className={classes.hr} />
						<UnitInfoIconList
							dataUnit={unit && unit}
						/>
						<div className={classes.hr} />
						{/* <div className={classes.unitDescription}>
						<p>With a sleek, current architectural envelope and minimal, modernist interior touches, Ash &amp; Edward’s bold design asks its residences to consider what they love and need most in a home, and demand the best of it.</p>
					</div> */}
						<div className={classes.furtherUnitDetailsButtons}>
							<ButtonGroup justifyContent="center">
								{/* <ButtonOutline
									label="Features"
									padding={20}
									textColour={ThemeData.colours.tealBlue}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.tealBlue}
									to="."
									firstInColl

									onClick={() => hooks.toggleIsFeaturesSlideUpOpen()}
								/> */}
								{/* <ButtonOutline
									label="Pricing"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.lighterBorderDarkOnLight}
									to="."
								/> */}
							</ButtonGroup>
						</div>
						<div className={classes.keyplan}>
							{/* <div className={classes.nArrow}>
							<img
								className={classes.nArrowArrow}
								src={northArrowIcon}
								alt="north arrow icon" />
							<div className={classes.nArrowN}>
								N
							</div>
						</div> */}
							<img
								className={classes.keyplanImg}
								src={NetData.imgUri( unit && unit.unit_plan_type.keyplan_image && unit.unit_plan_type.keyplan_image.url )}
								alt={unit && unit.unit_plan_type.keyplan_image && unit.unit_plan_type.keyplan_image.alternativeText} />
						</div>
					</div>
				</div>
			</motion.div>
			<VirtualTour
				isOpen={hooks.isVirtualTourOpen}
				src={hooks.siteConfiguration.data && hooks.siteConfiguration.data.virtual_tour_iframe_url}
				toggleVirtualTourFcn={() => hooks.toggleIsVirtualTourOpen()}
				doUseHeader={true}
			/>
			<SlideUpGenericHolder
				slideUpIsOpen={hooks.isFeaturesSlideUpOpen}
				toggleSlideUpIsOpen={hooks.toggleIsFeaturesSlideUpOpen}
				doUseHeader={true}
			>
				{/* <ContentPage
					overrideUrlContentPageSlug="home-features"
					doRenderHeader={false}
				/> */}
			</SlideUpGenericHolder>
			<SlideUpGenericHolder
				slideUpIsOpen={hooks.isViewStudySlideUpOpen}
				toggleSlideUpIsOpen={hooks.toggleIsViewStudySlideUpOpen}
				doUseHeader={true}
			>
				{hooks.isViewStudySlideUpOpen && unit &&
					<iframe
						title="View Study"
						width="100%"
						height="100%"
						frameBorder="0"
						src={unit.view_study_iframe_url}
					/>
				}
			</SlideUpGenericHolder>
		</>
	);
}



const UnitDetail = ( { unitId, isOpen, toggleUnitDetailFcn } ) => {

	const classes = useStyles();

	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	const siteConfiguration = useSiteConfiguration();

	const {
		isVirtualTourOpen,
		toggleIsVirtualTourOpen,
	} = useOpenClosePane( "VirtualTour" )
	const {
		isViewStudySlideUpOpen,
		toggleIsViewStudySlideUpOpen,
	} = useOpenClosePane( "ViewStudySlideUp" )
	const {
		isFeaturesSlideUpOpen,
		toggleIsFeaturesSlideUpOpen,
	} = useOpenClosePane( "FeaturesSlideUp" )

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
		siteConfiguration,
		isVirtualTourOpen,
		toggleIsVirtualTourOpen,
		isViewStudySlideUpOpen,
		toggleIsViewStudySlideUpOpen,
		isFeaturesSlideUpOpen,
		toggleIsFeaturesSlideUpOpen,
	}

	const { loading, error, data } = useQuery( QueryData.unitByCustomId, {
		variables: {
			customUnitId: unitId,
		}
	} );

	if ( loading ) return RenderUnitDetail( unitId, null, classes, null, isOpen, toggleUnitDetailFcn, hooks );
	if ( error ) return RenderUnitDetail( unitId, null, classes, error, isOpen, toggleUnitDetailFcn, hooks );

	let unit;
	if ( data && data.units.length > 0 )
		unit = data.units[0];

	// console.log( "udUnit.data", unit );
	// console.log( "ud.hooks", hooks );

	return RenderUnitDetail( unitId, unit, classes, null, isOpen, toggleUnitDetailFcn, hooks );

};



export default UnitDetail;