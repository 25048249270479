import React from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// FaArrowLeft,
} from "react-icons/fa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


import ThemeData from "../data/ThemeData";



// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";



const useStyles = createUseStyles(
	{
		header: props => ( {
			display: 'flex',
			alignItems: 'center',

			width: '100%',
			height: 100,

			backgroundColor: props.backgroundColor,

			zIndex: 2,
		} ),
		backButton: {
			paddingLeft: 40,

			cursor: 'pointer',

			fontFamily: ThemeData.fonts.title,
			color: ThemeData.colours.apricotOrange,
			fontSize: 24,
		},
	},
	{
		name: "HeaderSimple",
	}
)



const HeaderSimple = ( { onBackClick, ...props } ) => {

	const classes = useStyles( props );

	return (
		<div className={classes.header}>
			<div className={classes.backButton} onClick={onBackClick}>
				<FontAwesomeIcon icon={faArrowLeft} /> BACK
			</div>
		</div>
	);
}

HeaderSimple.defaultProps = {
	backgroundColor: ThemeData.colours.white50,
};



export default HeaderSimple;