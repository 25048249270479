
import ReactGA from 'react-ga4';

export const sendGoogleAnalyticsEvent = (category, action, label, value) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label,
		value: value
	})
};
