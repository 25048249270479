import {
	gql,
} from "@apollo/client";



const QueryData = {
	contentPagesQueryBodyText: `
		id
		order
		title
		card_title
		slug
		form {
			name
			value
		}
		content
		image {
			url
			alternativeText
			formats
		}
		text_media_pairs {
			text
			media {
				url
				alternativeText
				mime
				formats
			}
		}
		is_dark_x_on_light_bg
		is_only_image_card
		custom_link
		custom_function
		custom_iframe_embed_url
		section {
			slug
		}
		show_overview_video_button
		overview_page_video {
			url
			formats
		}
	`,

	galleryPhotosQueryText: `
		name
		order
		image {
			url
			alternativeText
			width
			height
			formats
		}
	`,
	galleryPhotosCategoryQueryText: `
		gallery_photo_categories {
			name
			do_display_in_main_gallery
		}
	`,
	neighbourhoodLocations: gql`
		query neighbourhoodLocations {
			neighbourhoodLocations {
				id
				number
				name
				latitude
				longitude
				street_address
				city
				province_short
				postal_code
				neighbourhood_location_category {
					  name
					  colour
					  is_colour_outline
					  do_show_in_list
				}
			}
		}
	`,
	neighbourhoodLocationCategories: gql`
		query neighbourhoodLocationCategories {
			neighbourhoodLocationCategories( where: {
				do_show_in_list: "true"
			} ) {
				id
				name
				order
				colour
				is_colour_outline
				do_show_in_list
				neighbourhood_locations {
					id
					number
					name
					latitude
					longitude
					street_address
					city
					province_short
					postal_code
				}
			}
		}
	`,

	sectionQuerySmall: `
		section {
			name
			slug
			content_pages {
				order
				slug
			}
		}
	`,

	siteConfiguration: gql`
		query SiteConfiguration {
			siteConfiguration {
				email_register_title_text,
				email_register_button_text,
				email_floorplans_title_text,
				email_floorplans_button_text,
				virtual_tour_iframe_url,
			}
		}
	`,

	siteFeatureToggles: gql`
		query SiteFeatureToggle {
			siteFeatureToggle {
				prices
			}
		}
	`,

	unitsQueryTextNoOpening: `
			id
			custom_id
			name
			unit_number
			interior_sqft
			exterior_sqft
			baths
			price
			is_typical_plan
			info_line_override
			floorplan_image {
				url
				alternativeText
				formats
			}
			keyplan_image {
				url
				alternativeText
				formats
			}
			view_study_iframe_url
			floorplan_pdf {
				url
			}
			unit_availability_state {
				id
				name
				value
			}
			unit_floor {
				id
				name
				number
			}
			unit_building {
				id
				name
			}
			unit_home_type {
				id
				name
				number_beds
				text_beds
			}
			unit_plan_type {
				id
				name
				units {
					id
				}
				floorplan_image {
					url
					alternativeText
					formats
				}
				keyplan_image {
					url
					alternativeText
					formats
				}
			}
			unit_exposures {
				id
				direction
			}
		}
	`,
};


QueryData.contentPageQuery = gql`
	query ContentPages( $slug: String! ) {
		contentPages( where: {
			slug: $slug
		} ) {
			${QueryData.contentPagesQueryBodyText}
			${QueryData.sectionQuerySmall}
		}
	}
`;
QueryData.contentPagesQueryText = `
	content_pages {
		${QueryData.contentPagesQueryBodyText}
	}
`;


QueryData.galleryPhotosMainGallery = gql`
	query GalleryPhotos {
		galleryPhotos( where: { gallery_photo_categories: { do_display_in_main_gallery: true } } ) {
			${QueryData.galleryPhotosQueryText}
			${QueryData.galleryPhotosCategoryQueryText}
		}
	}
`;

QueryData.galleryPhotos = gql`
	query GalleryPhotos {
		galleryPhotos {
			${QueryData.galleryPhotosQueryText}
		}
	}
`;

QueryData.galleryPhotosOfCategory = gql`
	query GalleryPhotos( $categoryName: String! ) {
		galleryPhotos( where: { gallery_photo_categories: { name: $categoryName } } ) {
			${QueryData.galleryPhotosQueryText}
			${QueryData.galleryPhotosCategoryQueryText}
		}
	}
`;

QueryData.galleryPhotosTest = gql`
		query galleryPhotos {
			galleryPhotos {
				name
				image {
					url
					alternativeText
					width
					height
					formats
				}
				gallery_photo_categories( where: { do_display_in_main_gallery: true } ) {
					name
					do_display_in_main_gallery
				}
			}
		}
	`;

QueryData.galleryPhotosTestNoWhere = gql`
		query galleryPhotos {
			galleryPhotos {
				name
				image {
					url
					alternativeText
					width
					height
					formats
				}
				gallery_photo_categories {
					name
					do_display_in_main_gallery
				}
			}
		}
	`;


QueryData.navQuery = gql`
	query NavItems {
		navItems(where: {
			nav: {
				slug: "topNav"
			}
		} ) {
			id
			order
			alt_name
			section {
				name
				slug
				custom_override_function
				${QueryData.contentPagesQueryText}
			}
		}
		contactInfo {
			email
			tel
			address
			map_image {
				url
				alternativeText
			}
			partner_images {
				id
				url
				alternativeText
				width
				height
			}
			tagline
			disclaimer
			powered_by_image {
				url
				alternativeText
			}
		}
		contactSocials {
			id
			network_name
			link
			icon {
				url
				alternativeText
			}
		}
	}
`;

QueryData.unitsQueryText = `
	units {
	${QueryData.unitsQueryTextNoOpening}
`;

QueryData.units = gql`
	query units {
		${QueryData.unitsQueryText}
	}
`;

QueryData.unitById = gql`
		query units( $unitId: ID! ) {
			unit (id: $unitId) {
			${QueryData.unitsQueryTextNoOpening}
		}
	`;

QueryData.unitByCustomId = gql`
		query units( $customUnitId: ID! ) {
			units (where: { custom_id: $customUnitId } ) {
			${QueryData.unitsQueryTextNoOpening}
		}
	`;


QueryData.unitProperties = gql`
	query unitProperties {
		${QueryData.unitsQueryText}
		unitAvailabilityStates {
			id
			name
			value
		}
		unitFloors {
			id
			name
			number
			units {
				id
				unit_number
			}
		}
		unitBuildings {
			id
			name
			units {
				id
				unit_number
			}
		}
		unitHomeTypes {
			id
			name
			number_beds
			text_beds
			units {
				id
				unit_number
			}
		}
		unitPlanTypes {
			id
			name
			floorplan_image {
				url
				alternativeText
				formats
			}
			keyplan_image {
				url
				alternativeText
				formats
			}
			units {
				id
				unit_number
			}
		}
		unitExposures {
			id
			direction
			directionAbbrev
			units {
				id
				unit_number
			}
		}
	}
`;



QueryData.section = gql`
	query Sections( $slug: String! ) {
		sections( where: {
			slug: $slug
		}) {
			name
			slug
			custom_override_function
			custom_override_url
			${QueryData.contentPagesQueryText}
		}
	}
`;

export default QueryData;