import React, {
	useEffect,
	useState,
	useMemo,
	// useRef,
} from "react";

import {
	useQuery,
	// gql
} from "@apollo/client";

import { createUseStyles } from "react-jss";

import {
	Link,
	useParams,
	useHistory,
	// useRouteMatch,
} from "react-router-dom";

import {
	motion,
	// AnimatePresence,
} from 'framer-motion';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import SwiperCore, {
	Controller,
	Pagination,
} from "swiper";


import ConstantData from "../data/ConstantData";
import ThemeData from "../data/ThemeData";
// import NetData from "../data/NetData";
import QueryData from "data/QueryData";
// import SlugData from "../data/SlugData";


import imgFunctions from "util/imgFunctions";


// import ContentPagePageIndicator from "./ContentPagePageIndicator";
import Map from "components/Map";
import ContentPageNeighbourhoodPois from "./ContentPageNeighbourhoodPois";
// import Gallery from "./Gallery";
import Floorplans from "./Floorplans";
import HeaderComponents from "./HeaderComponents";
import HeaderContextData from "data/HeaderContextData";
import VirtualTour from "./VirtualTour";
import ScrollIcon from "./ScrollIcon";


// import contentPageNavArrowLeft from "../img/content_page_nav_arrow_left.svg";
// import contentPageNavArrowRight from "../img/content_page_nav_arrow_right.svg";
import contentPageX from "../img/content_page_x.svg";
import contentPageXDark from "../img/content_page_x_dark.svg";

import richifyTextBasic from "util/richifyTextBasic"; 



SwiperCore.use( [Pagination] );



const useStyles = createUseStyles(
	{
		wrapper: {
			height: '100%',

			color: ThemeData.colours.black,
		},
		contentPage: {
			display: 'flex',
			flexDirection: 'row',

			height: 'calc( 100vh - var( --header-height ) )',
		},
		backButtonWrapper: {
			display: 'flex',
			alignItems: 'center',

		},
		backButton: {
			flex: '0 0 auto',

			paddingLeft: 40,

			color: ThemeData.colours.apricotOrange,
			fontFamily: ThemeData.fonts.title,
			fontSize: 24,
		},
		contentPane: {
			width: 390,
			height: 'calc( 100vh - var( --header-height ) )',

			flex: '0 0 auto',

			display: 'flex',
			flexDirection: 'column',

			backgroundColor: ThemeData.colours.secondaryBg,

			textAlign: 'left',

			'&.withMap': {
				width: 550,
			},
		},
		contentContainer: {
			flex: '1 1 auto',
			height: 'calc( 100vh - var( --header-height ) )',
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',

			backgroundColor: ThemeData.colours.pearlWhite,

			padding: 40,
		},
		navContainer: {
			height: 150,

			flex: '0 1 auto',

			padding: '0 40px 30px 40px',
		},
		navArrow: {
			marginTop: 30,
		},
		navArrowLeft: {

		},
		navArrowRight: {
			marginLeft: 20,
		},
		navArrowDisabled: {
			opacity: .1,
		},
		rightPane: {
			flex: '1 1 auto',
			width: '100%',
			height: '100%',

			background: 'no-repeat center',
			backgroundSize: 'cover',
			objectFit: 'contain',
		},
		rightPaneSlider: {
			flex: '1 1 auto',
			width: '100%',
			height: '90%',

			background: 'no-repeat center',
			backgroundSize: 'cover',
			objectFit: 'contain',
		},
		swiperSlideImg: {
			width: 10,
		},
		rightPaneVideo: {
			display: 'flex',
			objectFit: 'contain',
		},
		rightPaneFullsized: {
			backgroundSize: 'contain',
		},
		swiper: {
			'--swiper-theme-color': ThemeData.colours.pearlWhite,
		},
		contentArea: {
			flex: '0 0 auto',
		},
		sectionName: {
			flex: '0 0 auto',

			color: ThemeData.colours.apricotOrange,

			fontFamily: ThemeData.fonts.title,
			fontSize: 16,
		},
		title: {
			flex: '0 0 auto',

			color: ThemeData.colours.tealBlue,

			marginTop: '.5em',

			fontFamily: ThemeData.fonts.title,
			fontSize: '2.5em',
			letterSpacing: 0,
			lineHeight: '1.2em',
		},
		content: {
			flex: '1 1 auto',
			// height: 'calc( 100vh - 297px )',
			paddingRight: '20px !important',

			color: ThemeData.colours.secondaryFont,

			fontFamily: ThemeData.fonts.copy,
			fontSize: '1.2em',
			letterSpacing: '0.0125em',
			lineHeight: '2em',

			overflowY: 'auto',

			...ThemeData.styles.thinScrollbarDarkOnLight,
		},
		contentPageX: {
			position: 'absolute',
			right: 0,

			width: 150,
			height: 150,

			background: 'no-repeat center',
			backgroundImage: `url('${contentPageX}')`,

			filter: 'drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))',

			"&.isDark": {
				backgroundImage: `url('${contentPageXDark}')`,
			},
		},
		mapPane: {
			display: 'none',

			'&.doShow': {
				display: 'block',
			},
		},
		scrollIcon: {
			bottom: '30px !important',
			zIndex: 2,
		},
		disclaimer: {

			fontSize: '16px', 
			position: 'absolute',
			bottom: '3%',
    		right: '-15%',
			width: '50%',
			letterSpacing: 0,
			textShadow: '2px 2px 10px #000000',
			color: ThemeData.colours.pearlWhite,

			fontFamily: ThemeData.fonts.proximaNova,
		}
	},
	{
		name: "ContentPage",
	}
);



// const animationVariants = {
// 	toLeft: {

// 	},
// }



// function RenderContentPage( data = null, classes, error = null ) {

// }



function ContentPage( { ...props } ) {

	const classes = useStyles();
	// const apiUri = NetData.apiUri;

	let { contentPageSlug } = useParams();
	if ( props.overrideUrlContentPageSlug )
		contentPageSlug = props.overrideUrlContentPageSlug;

	// const matchUri = useRouteMatch();
	const history = useHistory();

	const unitFcns = {
		selectedUnitId: props.selectedUnitId,
		setSelectedUnitId: props.setSelectedUnitId,

		isUnitDetailOpen: props.isUnitDetailOpen,
		setIsUnitDetailOpen: props.setIsUnitDetailOpen,
		toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
	}

	const [highlightCatPoi, setHighlightCatPoi] = useState( {
		isHighActive: false,
		highCatId: -1,
		highPoiId: -1,
	} );

	useEffect( () => {
		// console.log( highlightCatPoi );
	}, [highlightCatPoi] );

	const [procFlyTo, setProcFlyTo] = useState( {
		doProcFlyTo: false,
		poiid: -1,
		catid: -1,
		coordinates: -1,
	} );

	// console.log( 'history', history );


	const { loading, error, data } = useQuery( QueryData.contentPageQuery, {
		variables: {
			slug: contentPageSlug,
		}
	} );



	const customFunctionContent = useMemo( () => {
		return {
			// [ConstantData.contentPageCustomFunctions.photoGallery]: (
			// 	<Gallery
			// 		withPhotosOfCategoryName={"Neighbourhood"}
			// 	/>
			// ),
			[ConstantData.contentPageCustomFunctions.floorplans]: (
				<Floorplans
					{...unitFcns}
				/>
			),
			[ConstantData.contentPageCustomFunctions.photosOnly]: (
				<>Ayy</>
			),
			[ConstantData.contentPageCustomFunctions.virtualTour]: (
				<VirtualTour
					isOpen={true}
					src={data ? data.contentPages.length > 0 && data.contentPages[0].custom_iframe_embed_url : ''}
					toggleVirtualTourFcn={() => history.goBack()}
				/>
			)
		};
	}, [ConstantData, data, unitFcns] )
	// TODO: Figure out how to not make 

	let doRenderInteractiveMap = false;
	// let doRenderNeighbourhoodPhotoGallery = false;
	// let doRenderFloorplans = false;
	let doOnlyRenderPhotos = false;



	const textMediaPairs = useMemo( () => {
		if ( data ) {
			const contentPage = data.contentPages[0];

			if ( contentPage.text_media_pairs && contentPage.text_media_pairs.length > 0 ) {
				// Consolidate normal content/image into tmp structure
				let tmpAll = [
					{
						text: contentPage.content,
						media: contentPage.image && contentPage.image.length > 0 && contentPage.image[0],
					}
				];

				for ( let tmp of contentPage.text_media_pairs ) {
					tmpAll.push( {
						text: tmp.text,
						media: tmp.media,
					} );
				}

				return tmpAll;
			}
		}

		return null;
	}, [data] )

	const doOnlyRenderSingleTextContent = useMemo( () => {
		if ( textMediaPairs && textMediaPairs.length > 0 ) {
			let areAllTMPTextsBlank = true;
			// textMediaPairs === 1: only one so render single
			// textMediaPairs > 1: check
			if ( textMediaPairs.length > 1 ) {
				for ( let i = 1; i < textMediaPairs.length; i++ ) {
					if ( textMediaPairs[i].text )
						areAllTMPTextsBlank = false;
				}
			}
			return areAllTMPTextsBlank;
		}
		return false;
	}, [textMediaPairs] )

	const [textSwiper, setTextSwiper] = useState( null );
	const [mediaSwiper, setMediaSwiper] = useState( null );


	if ( loading ) {
		return (
			<div>
				<p></p>
			</div>
		)
	};
	if ( error ) {
		return (
			<>
				<p>{console.log( 'cpa', error.message )}</p>
			</>
		);
	};



	let contentPage = data.contentPages[0];



	// console.log( 'cp', contentPage );



	const backLocation = `/${contentPage.section.slug}`;
	// const handleGoBack = () => history.push( backLocation );


	// let allContentPagesSection = contentPage.section.content_pages;
	// allContentPagesSection = allContentPagesSection.slice().sort( ( pageA, pageB ) => pageA.order - pageB.order );
	// console.log( allContentPagesSection );
	// let contentPageIdx = allContentPagesSection.findIndex( ( contentPage ) => contentPage.slug === contentPageSlug );
	// console.log( contentPageIdx );

	// let prevContentPageIdx =
	// 	contentPageIdx === 0 ?
	// 		null :
	// 		contentPageIdx - 1;
	// let nextContentPageIdx =
	// 	contentPageIdx === allContentPagesSection.length - 1 ?
	// 		null :
	// 		contentPageIdx + 1;

	// console.log( "curr:", contentPageIdx, "prevIdx:", prevContentPageIdx, "nextIdx:", nextContentPageIdx );


	// console.log(contentPage.section)

	doRenderInteractiveMap = contentPage.custom_function === ConstantData.contentPageCustomFunctions.interactiveMap;
	// doRenderNeighbourhoodPhotoGallery = contentPage.custom_function === ConstantData.contentPageCustomFunctions.photoGallery;
	// doRenderFloorplans = contentPage.custom_function === ConstantData.contentPageCustomFunctions.floorplans;
	doOnlyRenderPhotos = contentPage.custom_function === ConstantData.contentPageCustomFunctions.photosOnly;


	const renderContentPageMediaPane = ( contentPageMedia => {
		// console.log( 'cpm', currentPage );
		return (
			contentPageMedia && contentPageMedia.url.includes( '.mp4' ) ?
				<div
					className={`${classes.rightPane} ${classes.rightPaneVideo}`}
				>
					<video
						autoPlay
						muted
						loop
					>
						<source src={contentPageMedia.url} type="video/mp4" />
					</video>
				</div>
				:
					<div
						className={`${contentPage.section.slug == 'floorplans' ? classes.rightPaneSlider : classes.rightPane} ${doOnlyRenderPhotos ? classes.rightPaneFullsized : ''}`}
						style={contentPageMedia && { backgroundImage: `url('${imgFunctions.GetImgBySizeUri( contentPageMedia, ConstantData.imgSizes.xlarge )}')` }}>
						{contentPage.title == 'INTERIORS' &&
							<div className={classes.disclaimer}>
								{`Renderings are artists' concept only.`}
							</div>
						}
				</div>
		)
	} )



	return (
		// <AnimatePresence>
		<motion.div
			className={classes.wrapper} id={contentPage.id}
			initial={{ y: '100vh' }}
			animate={{ y: 0 }}
			exit={{ y: '100vh' }}
			transition={{ type: 'tween', duration: .5 }}
		>
			{props.doRenderHeader &&
				<HeaderComponents headerContext={HeaderContextData.contentPageHeaderContext}>
					<div className={classes.backButtonWrapper}>
						<Link
							// to={`/${contentPage.section.slug}`}
							to={backLocation}
						>
							<div
								className={classes.backButton}
							>
								<FontAwesomeIcon icon={faArrowLeft} /> BACK
							</div>
						</Link>
					</div>
				</HeaderComponents>
			}
			<div
				className={classes.contentPage}
			>
				{/* {doRenderNeighbourhoodPhotoGallery ?
					<Gallery
						withPhotosOfCategoryName={"Neighbourhood"}
					/> */}
				{contentPage.custom_function &&
					!( doRenderInteractiveMap || doOnlyRenderPhotos )
					?
					customFunctionContent[contentPage.custom_function]
					:
					<>
						{!doOnlyRenderPhotos &&
							<div className={`${classes.contentPane} ${doRenderInteractiveMap ? "withMap" : ""}`}>
								<div className={classes.contentContainer}>
									<h4 className={`${classes.contentArea} ${classes.sectionName}`}>
										{contentPage.section.name.toUpperCase()}
									</h4>
									<h2 className={`${classes.contentArea} ${classes.title}`}>
										{/* {contentPage.title.toUpperCase()} */}
										<div dangerouslySetInnerHTML={{
											__html: richifyTextBasic(contentPage.title)
										}} />
									</h2>
									<div
										className={`${classes.contentArea} ${classes.content}`}
									// noDefaultStyles
									// style={{ width: 150, height: "100%", }}
									>
										{doRenderInteractiveMap ?
											<ContentPageNeighbourhoodPois
												highlightCatPoi={highlightCatPoi}
												setHighlightCatPoi={setHighlightCatPoi}
												setProcFlyTo={setProcFlyTo}
											/>
											:
											!doOnlyRenderSingleTextContent && textMediaPairs && textMediaPairs.length > 0 ?
												<Swiper
													className={classes.rightPaneSwiper}
													modules={[Controller]}
													// onSlideChange={( swiper, three ) => console.log( 's', swiper, '\nridx', swiper.realIndex, '\naidx', swiper.activeIndex )}
													onSwiper={setTextSwiper}
													controller={{ control: mediaSwiper }}
													allowTouchMove={false}
												>
													{textMediaPairs
														.map( ( tmp, idx ) => (
															<SwiperSlide key={idx}>
																<ReactMarkdown rehypePlugins={[rehypeRaw]}>{tmp.text}</ReactMarkdown>
															</SwiperSlide>
														) )}
												</Swiper>
												:
												<ReactMarkdown rehypePlugins={[rehypeRaw]}>{contentPage.content}</ReactMarkdown>
										}
									</div>
								</div>
								{/* <div className={classes.navContainer}>
							<ContentPagePageIndicator contentPageId={contentPage.id} />
							{prevContentPageIdx != null &&
								<Link to={`/${contentPage.section.slug}/${allContentPagesSection[prevContentPageIdx].slug}`} >
									<img className={`${classes.navArrow} ${classes.navArrowLeft}`} src={contentPageNavArrowLeft} alt="Content Page Nav Arrow Left" />
								</Link>
							}
							{prevContentPageIdx == null &&
								<img className={`${classes.navArrow} ${classes.navArrowLeft} ${classes.navArrowDisabled}`} src={contentPageNavArrowLeft} alt="Content Page Nav Arrow Left" />
							}
							{nextContentPageIdx != null &&
								<Link to={`/${contentPage.section.slug}/${allContentPagesSection[nextContentPageIdx].slug}`} >
									<img className={`${classes.navArrow} ${classes.navArrowRight}`} src={contentPageNavArrowRight} alt="Content Page Nav Arrow Right" />
								</Link>
							}
							{nextContentPageIdx == null &&
								<img className={`${classes.navArrow} ${classes.navArrowRight} ${classes.navArrowDisabled}`} src={contentPageNavArrowRight} alt="Content Page Nav Arrow Right" />
							}
						</div> */}
							</div>}
						{/* <div ref={mapContainer} className={`${classes.rightPane} ${classes.map} ${doRenderInteractiveMap ? "doShow" : ""}`} /> */}
						<div className={`${classes.rightPane} ${classes.mapPane} ${doRenderInteractiveMap ? "doShow" : ""}`} >
							<Map
								doShow={doRenderInteractiveMap}
								highlightCatPoi={highlightCatPoi}
								setHighlightCatPoi={setHighlightCatPoi}
								procFlyTo={procFlyTo}
							/>
						</div>
						{doRenderInteractiveMap ?
							""
							:
							textMediaPairs && textMediaPairs.length > 0 ?
								// <div className={classes.rightPane}>
								<>
									<Swiper
										className={classes.swiper}
										modules={[Controller, Pagination]}
										// onSlideChange={( swiper, three ) => console.log( 's', swiper, '\nridx', swiper.realIndex, '\naidx', swiper.activeIndex )}
										onSwiper={setMediaSwiper}
										controller={{ control: textSwiper }}
										pagination={{ clickable: true }}
									>
										<ScrollIcon
											className={classes.scrollIcon}
											direction={ConstantData.scrollIconDir.horizontal} />
										{textMediaPairs
											.map( ( tmp, idx ) => (
												<SwiperSlide
													className={classes.swiperSlideImg}
													key={idx}
												>
													{renderContentPageMediaPane( tmp.media )}
												</SwiperSlide>
											) )}
										{/* </div> */}
									</Swiper>
								</>
								:
								renderContentPageMediaPane( contentPage.image && contentPage.image[0] )
						}
						{/* <Link to={`/${contentPage.section.slug}`}>
						<div className={`${classes.contentPageX} ${contentPage.is_dark_x_on_light_bg ? "isDark" : ""}`} />
					</Link> */}
					</>
				}
			</div>
		</motion.div>
		// </AnimatePresence>
	);
}



ContentPage.defaultProps = {
	doRenderHeader: true,
}



export default ContentPage;