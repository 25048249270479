import React, {
	// useEffect
} from "react";

import {
	createUseStyles
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
	// AnimatePresence,
} from "framer-motion";

// import createPersistedState from "use-persisted-state";

// import {
// 	BiCheck,
// } from "react-icons/bi";


import useSavedHomes from "hooks/useSavedHomes";


import { useLocation } from "react-router-dom";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
// import ButtonCircle from "components/ButtonCircle";
import UnitInfoPlanNameTitle from "components/UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";
// import UnitCardActionButton from "./UnitCardActionButton";


import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
// import QueryData from "../data/QueryData";
// import ConstantData from "data/ConstantData";


// import saveHomePlusLight from "../img/save_home_plus_light.svg";
// import plusIcon from "img/icon_plus.svg";



// const useSavedHomesState = createPersistedState( ConstantData.localStorageKeys.savedHomes );



const useStyles = createUseStyles(
	{
		wrapper: {
			// width: 330,
			// minHeight: 200,

			position: 'relative',
			// position: 'absolute',
			// right: 100,
			// top: 200,

			// transition: 'all 2s',

			paddingTop: 5,

			...ThemeData.styles.hoverCard,
			border: `10px rgba( 1, 1, 1, 0 ) solid`,
		},
		selectedUnitHighlight: {
			border: `10px ${ThemeData.colours.cadetBlue} solid`,
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 190,

			borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
		},
		details: {
			position: 'relative',

			minHeight: 63,

			padding: '20px 20px 5px 20px',

			textAlign: 'left',

			// '& h4': {
			// 	margin: 0,
			// 	padding: 0,

			// 	fontFamily: ThemeData.fonts.title,
			// 	fontSize: '24px',
			// },

			'& p': {
			},
		},
		unitInfoPlanNameTitle: {
			margin: 0,
			padding: 0,

			color: ThemeData.colours.secondaryFont,

			fontFamily: ThemeData.fonts.title,
			fontSize: '24px',
		},
		unitInfoLine: {
			marginTop: 10,

			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: '12px',
			letterSpacing: '0.05em',
		},
		actionButton: {
			position: 'absolute',
			top: 16,
			right: 20,

			fontSize: 33,
			lineHeight: '32px',
		},
		buttonArea: {
			paddingInline: 20,
			paddingBlockEnd: 20,

		},
		moreDetailsButtonArea: {
			borderTop: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
	},
	{
		name: "UnitDetailCardGeneric",
	}
)


// function toggleSavedHome( hooks, unitId ) {
// 	let prevUnitIdExistsIdx = hooks.savedHomes.findIndex(elm => elm.id == unitId);
// 	if (prevUnitIdExistsIdx === -1)
// 		hooks.setSavedHomes([...hooks.savedHomes, { id: unitId }]);
// 	else {
// 		let newSavedHomes = hooks.savedHomes;
// 		newSavedHomes.splice(prevUnitIdExistsIdx, 1);
// 		hooks.setSavedHomes([...newSavedHomes]);
// 	}
// }


function renderUnitDetailCardGeneric( dataUnit, classes, error, toggleUnitDetailPaneFcn, hooks, location, props ) {
	const doDisplayCard = true; //!( selectedUnitId === -1 );

	const apiUri = NetData.apiUri;

	return (
		<motion.div
			className={
				`${props.className
				} ${classes.wrapper
				} ${doDisplayCard ? "" : classes.hidden
				} ${dataUnit.custom_id === props.selectedUnitId && props.isToAllowSelection ? classes.selectedUnitHighlight : ""
				}`}
			onClick={() =>
				// props.isToAllowSelection && props.setSelectedUnitId( dataUnit.custom_id )
				props.setSelectedUnitId( dataUnit.custom_id )
			}
		>
			<div className={classes.details}>
				{props.unitCardActionButton}
				<UnitInfoPlanNameTitle
					data={dataUnit}
					fontSize={36}
					doShowUnitNumber={true}
					className={classes.unitInfoPlanNameTitle}
				/>
				<div className={classes.unitInfoLine}>{dataUnit && <UnitInfoLine
					data={dataUnit}
				/>}</div>
			</div>
			<div className={classes.floorplanImage}>
				{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
					<img
						src={`${NetData.imgUri( dataUnit.unit_plan_type.floorplan_image.formats && dataUnit.unit_plan_type.floorplan_image.formats.small ?
							dataUnit.unit_plan_type.floorplan_image.formats.small.url :
							dataUnit.unit_plan_type.floorplan_image.url )} `}
						alt={dataUnit.unit_plan_type.floorplan_image.alternativeText}
						width={'auto'}
						height={160}
					/>
				}
				{/* {( !dataUnit || !dataUnit.unit_plan_type || !dataUnit.unit_plan_type.floorplan_image ) &&
					<img
						src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
						alt='floorplan placeholder'
						width={331}
						height={187}
					/>
				} */}
			</div>
			<div className={classes.buttonArea}>
				<ButtonGroup
					justifyContent='center'
				>
					<ButtonOutline
						label="Details"
						// padding={}
						textColour={ThemeData.colours.prussianBlue}
						hoverTextColour={ThemeData.colours.pearlWhite}
						borderColour={ThemeData.colours.tealBlue}
						hoverBackgroundColour={ThemeData.colours.tealBlue}
						to="."
						onClick={() => {
							props.onClickLeftButton();
							sendGoogleAnalyticsEvent(
								`Floor Plan View - ${location.pathname}`,
								`User Clicks ${dataUnit.unit_plan_type.name} Unit ${dataUnit.unit_number}`,
								`${dataUnit.unit_plan_type.name} Unit ${dataUnit.unit_number}`,
								`${dataUnit.unit_plan_type.name} Unit ${dataUnit.unit_number}`
							);
						}
					}
						firstInColl
					/>
					{/* <ButtonOutline
						label="Take the Tour"
						// padding={20}
						textColour={ThemeData.colours.secondaryFont}
						hoverTextColour={ThemeData.colours.primaryFontHighestContrast}
						borderColour={ThemeData.colours.lightBorderDarkOnLight}
						onClick={() => props.toggleVirtualTourPaneFcn()}
						to="."
					/> */}
				</ButtonGroup>
			</div>
			{/* <div className={classes.moreDetailsButtonArea}>
				<ButtonOutline
					label="More Details"
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.eggshellTextBg}
					onClick={toggleUnitDetailPaneFcn}
				/>
			</div> */}
		</motion.div>
	);
}


function UnitDetailCardGeneric( { dataUnit, toggleUnitDetailPaneFcn, ...props } ) {
	const location = useLocation();
	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	// console.log( "udcg.saved", savedHomes );

	let hooks = {
		savedHomes,
		toggleSavedHome
	}


	// console.log( 'udcg', dataUnit );


	return renderUnitDetailCardGeneric( dataUnit, classes, null, toggleUnitDetailPaneFcn, hooks, location, props );
}



UnitDetailCardGeneric.defaultProps = {
	isToAllowSelection: false,
}



export default UnitDetailCardGeneric;