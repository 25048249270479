import React, {
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


// import ThemeData from "data/ThemeData";


import FloorplansGeneric from "./FloorplansGeneric";


// import UnitFcnContext from "contexts/UnitFcnContext";



const useStyles = createUseStyles(
	{
		wrapper: {
			width: '100%',
		},
	},
	{
		name: "Floorplans",
	}
)



const Floorplans = ( { data, className, ...props } ) => {

	const classes = useStyles();

	// const unitFcns = {
	// 	selectedUnitId: props.selectedUnitId,
	// 	setSelectedUnitId: props.setSelectedUnitId,

	// 	isUnitDetailOpen: props.isUnitDetailOpen,
	// 	setIsUnitDetailOpen: props.setIsUnitDetailOpen,
	// 	toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
	// }

	// const [selectedUnitId, setSelectedUnitId] = useState( null );
	const [selectedUnitData, setSelectedUnitData] = useState( null );


	return (
		<div className={`${classes.wrapper} ${className}`}>
			<FloorplansGeneric
				isToAllowCompare={props.isToAllowCompare}
				overrideBackgroundColor={props.overrideBackgroundColor}

				unitCardActionButtonProps={props.unitCardActionButtonProps}

				selectedUnitData={selectedUnitData}
				setSelectedUnitData={setSelectedUnitData}
			/>
		</div>
	);
}



Floorplans.defaultProps = {
	isToAllowCompare: true,
	overrideBackgroundColor: null,
}



export default Floorplans;