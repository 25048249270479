import React, {
	// useState
} from "react";
import {
	// Link
} from "react-router-dom";
import { createUseStyles } from "react-jss";


import {
	useQuery,
	// gql
} from "@apollo/client";


import {
	motion,
	useCycle,
} from "framer-motion";


import ThemeData from "../data/ThemeData";
// import NetData from "../data/NetData";
import QueryData from "data/QueryData";
import HeaderContextData from "../data/HeaderContextData";

import HeaderComponents from "./HeaderComponents";
import TopMenu from "./TopMenu";

import mainMenuOpenIcon from "../img/main_menu_open.svg";
import mainMenuCloseIcon from "../img/main_menu_close.svg";


const useStyles = createUseStyles(
	{
		header: {
			flex: '0 0 auto',
			// display: 'flex',
			justifyContent: 'space-between',
			boxSizing: 'border-box',
			height: 'var( --header-height )',

			backgroundColor: ThemeData.colours.primaryBgTransparent,

			borderBottom: ThemeData.colours.paneBorders,
		},
		mainMenuButton: {
			flex: '0 1 auto',

			width: 100,
			height: 100,

			cursor: 'pointer',

			background: 'no-repeat center',

			fontFamily: ThemeData.fonts.title,
		},
		openMainMenuButton: {
			backgroundImage: `url( ${mainMenuOpenIcon} )`,
		},
		closeMainMenuButton: {
			backgroundImage: `url( ${mainMenuCloseIcon} )`,
		},
		closeMainMenuText: {
			...ThemeData.typography.nameplate,

			cursor: 'pointer',
		},
		mainMenu: {
			// display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',

			position: 'absolute',
			// top: 'calc(100px - 100vh)',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',

			display: 'none',

			'.isOpen &': {
				display: 'block',
			},

			backgroundColor: ThemeData.colours.primaryBg,
		},
		mainMenuHeader: {
			flex: '0 1 auto',

			width: '100%',
			height: 100,

			// backgroundColor: 'inherit',
			// VonE49 colour
			backgroundColor: ThemeData.colours.prussianBlue,
		},
	},
	{
		name: "HeaderContainer",
	}
);

const variants = {
	open: {
		// opacity: 1,
		y: 0,
		zIndex: 5,
		display: 'block',
	},
	closed: {
		// opacity: 0,
		y: "-100vh",
		display: 'block',
		transitionEnd: { zIndex: -2, display: 'none', },
	}
}


const HeaderContainer = ( { ...props } ) => {

	const [isOpen, toggleOpen] = useCycle( false, true );
	const classes = useStyles();

	const { loading, error, data } = useQuery( QueryData.navQuery );

	if ( loading ) return <p></p>
	if ( error ) return <p>{console.log('hco', error.message )}</p>

	// console.log( data );

	return (
		<header className={`${classes.header}`}>
			<HeaderComponents
				headerContext={HeaderContextData.siteHeaderContext}
				toggleIsSavedHomesSlideoutOpen={() => props.toggleIsSavedHomesSlideoutOpen()}
				toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
			>
				<div className={`${classes.mainMenuButton} ${classes.openMainMenuButton}`} onClick={() => toggleOpen( isOpen => !isOpen )} />
			</HeaderComponents>
			<motion.div
				className={classes.mainMenu}
				initial={false}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ type: 'spring', bounce: .25, duration: .5 }}
			>
				<header className={`${classes.header} ${classes.mainMenuHeader}`}>
					<HeaderComponents headerContext={HeaderContextData.mainMenuHeaderContext}>
						<div className={`${classes.mainMenuButton} ${classes.closeMainMenuButton}`} onClick={() => toggleOpen( isOpen => !isOpen )} />
						<div className={`${classes.closeMainMenuText}`} onClick={() => toggleOpen( isOpen => !isOpen )}>
							<p>Close Menu</p>
						</div>
					</HeaderComponents>
				</header>
				<TopMenu
					dataNav={data}
					/>
			</motion.div>
		</header>
	)

};


export default HeaderContainer;