// import useRegistration from "hooks/useRegistration";



const useSendEmail = () => {

	return {
		sendSavedHomesEmail: ( name, email, savedHomesData ) => {
			let xhr = new XMLHttpRequest();
			xhr.open( 'POST', '/api/email/saved-homes' );
			xhr.setRequestHeader( 'CONTENT-TYPE', 'application/json' );
			xhr.send( 
				JSON.stringify(
					{
						name: name,
						email: email,
						savedHomesData: savedHomesData,
					}
				)
			)
		},
	}
}



export default useSendEmail;